<template>
    <div class="input-link form-control">
        <template v-if="!linkValue">
            <b-btn variant="light-primary" @click="show()">
                <i class="fas fa-edit mr-1"/>
                {{placeholder|tr}}
            </b-btn>
        </template>

        <template v-else>
            <div class="d-flex">
                <b-img rounded v-if="linkValue && linkValue.thumbnail" width="40" height="40" class="mr-2" :src="linkValue.thumbnail"/>
                <i v-else class="mr-2 fas" :class="linkValue.getType().glyph"/>
                <div class="overview" style="line-height: 1.2">
                    <div class="type">{{linkValue.getType().label|tr}}</div>
                    <span class="name f-10" v-if="linkValue.name">{{linkValue.name|tr}}</span>
                    <span class="url f-9" v-else>{{linkValue.url|tr}}</span>
                </div>

                <b-btn variant="light" class="ml-2 px-2" @click="show()">
                    <span v-tr>Change|Changer</span>
                </b-btn>

                <b-btn variant="light" class="ml-1 px-2" :disabled="!linkValue || !linkValue.href" :title="'Open in new tab|Ouvrir dans un autre onglet'|tr" v-b-tooltip target="_blank" :href="linkValue.href">
                    <i class="fas fa-external-link"/>
                </b-btn>
            </div>
        </template>

        <link-picker v-if="pickerActive" v-model="link" :visible.sync="pickerVisible" @apply="apply" @hide="reset" @clear="clear" hide-current-page :allow-empty="true"/>
    </div>
</template>

<script>
import InputFile from "@/components/editor/properties/inputs/input-file.vue";
import LinkPicker from "@/components/editor/properties/inputs/link-picker.vue";
import CmsLink from "@/helpers/cms/CmsLink.js";

// input-link #hot-reload-debug
export default {
    name: `input-link`,
    components: {LinkPicker, InputFile},
    props: {
        value: {},
        title: {type: String},
        thumbnail: {type: String},
        placeholder: {type: String, default: `Add Link|Ajouter un lien`},
        showEdit: {type: Boolean},
        allowEmpty: {type: Boolean}
    },
    data() {
        return {
            pickerActive: false,
            pickerVisible: false,
            link: null
        }
    },
    computed: {
        linkValue: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit(`input`, value);
            }
        }
    },
    mounted() {
        this.reset();
    },
    methods: {
        apply() {
            this.linkValue = this.link;
            this.pickerVisible = false;
        },
        clear() {
            this.linkValue = null;
            this.pickerVisible = false;
        },
        show() {
            this.pickerActive = true;
            this.pickerVisible = true;
        },
        reducePage(page) {
            return {
                id: page.id,
                name: page.name,
                permalink: page.permalink
            }
        },
        reset() {
            if (!this.linkValue || typeof this.linkValue === `string`) {
                this.$set(this, `link`, new CmsLink({
                    type: `external`,
                    url: this.linkValue,
                    openInNewTab: true,
                    value: this.linkValue
                }));
            } else {
                this.$set(this, `link`, new CmsLink(this.linkValue));
            }
            this.pickerVisible = false;
        }
    },
    watch: {
        linkValue() {
            this.reset();
        }
    }
}
</script>

<style lang="scss" scoped>
.input-link {
    padding: 4px;
    height: 52px;

    .d-flex {
        height: 100%;
        align-items: center;

        .overview {
            display: inline-flex;
            flex-direction: column;
            justify-content: center;
            flex: 1;
            height: 100%;
            overflow: hidden;
            font-size: 10pt;
        }

        .type {
            font-weight: 500;
            margin-bottom: 3px;
        }

        .name, .url {
            font-size: 10pt;
            width: 100%;
            overflow: hidden;
            white-space: nowrap;
        }
    }
}
</style>
