<template>
    <div @click="$emit(`click`, color)" class="input-color-preview" :class="[{'selected': selected, 'transparent': color === `transparent`, 'default': color === `default`}, `size-${size}`]" :style="style">
<!--        <i v-if="transparent" class="icon-transparent"/>-->
        <slot></slot>
        <i v-if="selected" class="fas fa-check icon-check"/>
    </div>
</template>

<script>

// input-color-preview #hot-reload-debug
import ColorHelper from "../../../../../vue-components/helpers/ColorHelper.js";

export default {
    name: `input-color-preview`,
    props: {
        color: {type: String},
        size: {type: String, default: `md`},
        selected: {type: Boolean}
    },
    computed: {
        colorComputed() {
            return this.cmsWebsite.getColor(this.color);
        },
        rgba() {
            return this.hexToRgb(this.colorComputed);
        },
        textColor() {
            return ColorHelper.getColorContrast(this.colorComputed);
        },
        style() {
            if (this.transparent) {
                return {};
            }
            return {
                background: this.colorComputed,
                color: this.textColor
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.input-color-preview {
    position: relative;
    display: inline-flex;
    vertical-align: top;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border: 2px solid rgba(black, 0.2);
    border-radius: 4px;
    cursor: pointer;

    &.size-sm {
        width: 34px;
        height: 34px;
    }

    &.selected {
        transform: scale(0.95);
    }

    .icon-check {
        position: absolute;
    }

    &.transparent {
        color: #fafafa;

        &::after {
            content: "";
            position: absolute;
            display: block;
            top: -8px;
            bottom: -9px;
            left: calc(50% - 1.5px);
            width: 4px;
            transform: rotate(45deg);
            border-radius: 2px;
            background-color: red;
        }
    }

    &.default {
        background: #eee !important;
        color: #000 !important;

        //&::after {
        //    content: "";
        //    position: absolute;
        //    display: block;
        //    top: -8px;
        //    bottom: -9px;
        //    left: calc(50% - 1.5px);
        //    width: 4px;
        //    transform: rotate(45deg);
        //    border-radius: 2px;
        //    background-color: red;
        //}
    }
}
</style>
