import { render, staticRenderFns } from "./input-border-radius.vue?vue&type=template&id=51793ebb&scoped=true"
import script from "./input-border-radius.vue?vue&type=script&lang=js"
export * from "./input-border-radius.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "51793ebb",
  null
  
)

export default component.exports