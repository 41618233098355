<template>
    <b-btn variant="radio" :class="internalValue ? activeClass : ''" :size="size" @click="toggleValue">
        <slot>{{label|tr}}</slot>
        <i class="fas fa-check floating-check-mark ml-1" v-if="internalValue"/>
    </b-btn>
</template>

<script>
    export default {
        name: `btn-checkbox`,
        props: {
            value: {},
            label: {type: String, default: ``},
            activeClass: {type: String, default: `selected`},
            size: {type: String, default: `md`},
            justify: {type: Boolean}
        },
        computed: {
            internalValue: {
                get() {
                    return this.value;
                },
                set(value) {
                    this.$emit(`input`, value);
                }
            }
        },
        methods: {
            toggleValue() {
                this.internalValue = !this.internalValue;
                this.$emit(`change`, this.internalValue);
                this.$emit(`clicked`, this.internalValue);
            }
        }
    }
</script>

<style lang="scss">
.btn-radio {
    position: relative;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 40px;
    width: 55px;
    font-size: 14px;
    background-color: white;
    border-radius: var(--border-radius);
    border: 2px solid rgba(black, 0.2);
    cursor: pointer;

    & + .btn-radio {
        margin-left: 5px;
    }

    &.square {
        width: 40px;
    }

    &:hover {
        border-color: var(--accent-color);
    }

    &.selected {
        transform: scale(0.95);
        border-color: var(--accent-color);
    }

    .floating-check-mark {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        bottom: -5px;
        right: -5px;
        border: 2px solid white;
        background: var(--accent-color);
        color: white;
        border-radius: 50%;
        font-size: 14px;
        width: 20px;
        height: 20px;
    }
}
</style>
