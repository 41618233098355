<template>
    <div class="btn-button-type" @click="currentButtonType = presetDef.value" :class="[`type-${presetDef.value}`, {'selected': isSelected}]">
        <span v-tr>{{presetDef.title|tr}}</span>
        <i class="fas fa-check floating-check-mark ml-1" v-if="isSelected"/>
    </div>
</template>

<script>
export default {
    name: `btn-button-type`,
    props: {
        value: {type: String},
        preset: {type: String}
    },
    data() {
        return {
            presets: [
                {title: `Solid|Plein` , value: `solid`},
                {title: `Outline|Entouré` , value: `outline`},
                {title: `Light|Léger` , value: `light`},
                {title: `Text|Texte` , value: `text`}
            ]
        }
    },
    computed: {
        presetDef() {
            return this.presets.find(p => p.value === this.preset);
        },
        currentButtonType: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit(`input`, value);
            }
        },
        isSelected() {
            return this.currentButtonType && this.currentButtonType === this.presetDef.value;
        }
    }
}
</script>

<style lang="scss" scoped>
.btn-button-type {
    position: relative;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 40px;
    width: 60px;
    font-weight: 500;
    //margin-right: 5px;
    background-color: white;
    border-radius: var(--border-radius);
    border: 2px solid transparent;
    cursor: pointer;

    & + .btn-button-type {
        margin-left: 5px;
    }

    &.type-solid {
        background: var(--accent-color);
        color: #fff;
    }

    &.type-outline {
        border-color: var(--accent-color);
        color: var(--accent-color);
    }

    &.type-light {
        background: white;
        color: var(--accent-color);
    }

    &.type-text {
        background: transparent;
        color: #333;
    }

    span {
        font-size: 10pt;
    }

    .preview-title {
        font-size: 14px;
        font-weight: 700;
        line-height: 1;
        margin-bottom: 6px;
        text-align: center;
    }

    &:hover {
        //border-color: var(--accent-color);
    }

    &.selected {
        transform: scale(0.95);
        //border-color: var(--accent-color);
    }
}
</style>
