<template>
    <div class="sidebar-group" :class="{ 'sub-group': subGroup }">
        <div class="sidebar-group-header">
            <i class="fas float-right toggle-expanded" @click="expandedComputed = !expandedComputed"
               :class="[expandedComputed ? 'fa-chevron-up' : 'fa-chevron-down']" style="line-height: 26px;"/>

            <slot name="actions"></slot>

            <component :is="subGroup ? 'h6' : 'h4'"><slot name="title">{{title|tr}}</slot></component>
        </div>

        <div class="sidebar-group-body" v-show="expandedComputed">
            <slot></slot>
        </div>
    </div>
</template>

<script>

// sidebar-group #hot-reload-debug
export default {
    name: `sidebar-group`,
    props: {
        title: {type: String},
        subGroup: {type: Boolean},
        expanded: {type: Boolean, default: null}
    },
    data() {
        return {
            fallbackExpanded: true
        }
    },
    computed: {
        expandedComputed: {
            get() {
                if (this.expanded === null) {
                    return this.fallbackExpanded;
                }
                return this.expanded;
            },
            set(value) {
                if (this.expanded === null) {
                    this.fallbackExpanded = value;
                }
                this.$emit(`update:expanded`, value);
            }
        }
    }
}
</script>

<style lang="scss">
.sidebar-group {
    &.sub-group {
        background: white;
        border: 2px solid var(--border-color);
        padding: 10px 10px;
        border-radius: var(--border-radius);
        margin-bottom: 1rem;
    }

    & + .sidebar-group {
        padding-top: 10px;
        margin-top: 10px;
        border-top: 2px solid var(--border-color);
    }

    .sidebar-group-header {
        h4, h6 {
            margin: 0;
        }

        .custom-switch {
            position: relative;
            top: -3px;
            float: right;
        }
    }

    .sidebar-group-body {
        margin-top: 1rem;
    }

    .toggle-expanded {
        line-height: 26px;
        top: -2px;
        position: relative;
        opacity: 0.6;
        cursor: pointer;

        &:hover {
            color: #000;
            opacity: 1;
        }
    }
}
</style>
