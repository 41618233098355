<template>
    <b-btn class="e-btn-checkbox" :variant="internalValue ? activeVariant : variant" :size="size" @click="internalValue = !internalValue">
        <i class="mr-1" :class="internalValue ? checkedIcon : uncheckedIcon"/>
        <slot></slot>
    </b-btn>
</template>

<script>
    export default {
        name: `e-btn-checkbox`,
        props: {
            value: {},
            size: {type: String, default: `md`},
            variant: {type: String, default: `light`},
            activeVariant: {type: String, default: `primary`},
            uncheckedIcon: {type: String, default: `fal fa-square`},
            checkedIcon: {type: String, default: `fas fa-square-check`}
        },
        computed: {
            internalValue: {
                get() {
                    return this.value;
                },
                set(value) {
                    this.$emit(`input`, value);
                }
            }
        },
        methods: {
            setValue(value) {
                this.internalValue = value;
                this.$emit(`change`, this.value);
                this.$emit(`clicked`, this.value);
            }
        }
    }
</script>

<style lang="scss" scoped>
.e-btn-radio {
    .btn {
        flex: 1;
    }
}
</style>
