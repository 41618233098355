<template>
    <div class="btn-color-scheme" @click="currentColorScheme = colorScheme" :class="{'selected': isSelected}" :style="{ backgroundColor: cmsWebsite.getColor(colorScheme.background) }">
        <slot name="prepend"/>
        <slot>
            <i class="fas fa-text-size preview-title" :style="{ color: colorScheme.title }"/>
            <div class="preview-button" :style="{ backgroundColor: cmsWebsite.getColor(colorScheme.buttonBG) }"/>
            <i class="fas fa-check floating-check-mark ml-1" v-if="isSelected"/>
        </slot>
    </div>
</template>

<script>
export default {
    name: `btn-color-scheme`,
    props: {
        value: {type: Object},
        colorScheme: {type: Object}
    },
    computed: {
        currentColorScheme: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit(`input`, value);
            }
        },
        isSelected() {
            return this.currentColorScheme && this.currentColorScheme.id === this.colorScheme.id;
        }
    }
}
</script>

<style lang="scss" scoped>
.btn-color-scheme {
    display: inline-flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    vertical-align: top;
    height: 44px;
    width: 70px;
    margin: 3px;
    background-color: white;
    border-radius: var(--border-radius);
    border: 2px solid rgba(black, 0.2);
    cursor: pointer;

    .preview-title {
        font-size: 14px;
        font-weight: 700;
        line-height: 1;
        margin-bottom: 6px;
        text-align: center;
    }

    .preview-button {
        border-radius: 8px;
        height: 8px;
        width: 24px;
    }

    &:hover {
        border-color: var(--accent-color);
    }

    &.selected {
        transform: scale(0.95);
        border-color: var(--accent-color);
    }
}
</style>
