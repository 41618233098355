<template>
    <div class="responsive-preview" :class="[`view-` + view, `theme-` + theme, { 'iframe-mode': iframeMode }]">
        <div v-if="$slots.header" class="header">
            <b-dropdown right :no-caret="true">
                <template v-slot:button-content>
                    <i v-if="view === 'mobile'" class="fas fa-mobile-alt"></i>
                    <i v-else-if="view === 'tablet'" class="fas fa-tablet-alt"></i>
                    <i v-else-if="view === 'desktop'" class="fas fa-desktop-alt"></i>
                    <i v-else-if="view === 'fullscreen'" class="fas fa-compress-arrows-alt"></i>
                </template>
                <b-dropdown-item @click="view = 'mobile'" v-if="!noMobile && view !== 'mobile'">
                    <i class="fas fa-mobile-alt"></i> <span v-tr>Mobile|Mobile</span>
                </b-dropdown-item>
                <b-dropdown-item @click="view = 'tablet'" v-if="!noTablet && view !== 'tablet'">
                    <i class="fas fa-tablet-alt"></i> <span v-tr>Tablet|Tablette</span>
                </b-dropdown-item>
                <b-dropdown-item @click="view = 'desktop'" v-if="!noFullscreen && view !== 'desktop'">
                    <i class="fas fa-desktop"></i> <span v-tr>Desktop|Ordinateur</span>
                </b-dropdown-item>
                <b-dropdown-item @click="view = 'fullscreen'" v-if="!noFullscreen && view !== 'fullscreen'">
                    <i class="fas fa-expand"></i> <span v-tr>Fullscreen|Plein écran</span>
                </b-dropdown-item>
            </b-dropdown>
            <slot name="header"></slot>
        </div>
        <div class="content" :style="contentStyle">
            <slot></slot>
        </div>
    </div>
</template>

<script>
    export default {
        name: `e-responsive-preview`,
        props: {
            defaultView: { type: String, default: `mobile` },
            value: { type: String },
            theme: { type: String, default: `light` },
            iframeMode: { type: Boolean, default: false },
            noUserSelect: { type: Boolean, default: false },
            noTablet: { type: Boolean, default: false },
            noDesktop: { type: Boolean, default: false },
            noFullscreen: { type: Boolean, default: false },
            noMobile: { type: Boolean, default: false },
            contentStyle: {}
        },
        data() {
            return {
                view: `mobile`,
                fullscreen: false
            }
        },
        mounted() {
            this.view = this.defaultView;

            if (this.noMobile && this.view === `mobile`) {
                this.view = `tablet`;
            }
            if (this.noTablet && this.view === `tablet`) {
                this.view = `desktop`;
            }
            if (this.noDesktop && this.view === `desktop`) {
                this.view = `mobile`;
            }
            if (this.noFullscreen && this.view === `fullscreen`) {
                this.view = `fullscreen`;
            }
        },
        watch: {
            view() {
                this.$emit(`input`, this.view);
            }
        }
    }
</script>

<style lang="scss" scoped>
    .responsive-preview {
        border: 2px solid #ccc;
        border-radius: 5px;
        background-color: white;
        position: relative;
        width: 750px;
        margin: 0 auto;
        transition: top 500ms, width 500ms, height 500ms, margin 500ms;
        display: flex;
        flex-direction: column;

        &.no-select {
            user-select: none !important;
        }

        &.view-tablet {
            width: 1024px;
            height: 768px;
        }

        &.view-fullscreen {
            position: fixed;
            z-index: 999;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border: 0;
            margin: 0 !important;
        }

        &.view-desktop {
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border: 0;
        }

        &.view-mobile {
            width: 340px;
            min-width: 340px;
            height: 572px;
            top: 50px;

            &:before {
                content: "";
                display: block;
                position: absolute;
                top: -50px;
                left: -10px;
                width: 100%;
                height: 568px;
                border: 10px solid white;
                border-top-width: 50px;
                border-bottom-width: 50px;
                border-radius: 25px;
                box-sizing: content-box;
                pointer-events: none;
                box-shadow: inset 0 0 0 2px #ddd, 0 0 0 2px #ddd;
                background: none;
                opacity: 1;
                z-index: 1;
            }

            &:after {
                content: "";
                display: block;
                position: absolute;
                bottom: -42px;
                left: calc(50% - 17px);
                width: 34px;
                height: 34px;
                border-radius: 50%;
                background-color: #fafafa;
                box-shadow: inset 0 0 0 2px #dddddd;
                box-sizing: content-box;
                pointer-events: none;
                z-index: 1;
            }

            &.theme-dark {
                &:before {
                    border-color: #191919;
                    box-shadow: inset 0 0 0 2px #5a5a5a, 0 0 0 2px #505050, 0 0 20px rgba(0, 0, 0, 0.5);
                }

                &:after {
                    background-color: #191919;
                    box-shadow: inset 0 0 0 2px #444444;
                }
            }
        }

        &.view-desktop {
            border: 0;
            top: 20px;
            margin: 20px 10px 50px;
            width: calc(100% - 20px);

            &:before {
                content: "";
                display: block;
                position: absolute;
                top: -30px;
                left: -12px;
                width: calc(100% + 4px);
                height: 100%;
                border: 10px solid #fff;
                border-top-width: 30px;
                //border-bottom-width: 20px;
                border-radius: 5px;
                box-sizing: content-box;
                pointer-events: none;
                box-shadow: inset 0 0 0 2px #ddd, 0 0 0 2px #ddd;
                background: none;
                opacity: 1;
                z-index: 1;
            }

            &:after {
                content: "";
                display: block;
                position: absolute;
                top: -21px;
                left: calc(50% - 40px);
                width: 80px;
                height: 14px;
                border-radius: 12px;
                background-color: #eee;
                box-shadow: inset 0 0 0 2px #ddd;
                box-sizing: content-box;
                pointer-events: none;
                z-index: 1;
            }

            iframe {
                min-height: 512px;
            }

            &.theme-dark {
                &:before {
                    border-color: #191919;
                    box-shadow: inset 0 0 0 2px #5a5a5a, 0 0 0 2px #505050, 0 0 20px rgba(0, 0, 0, 0.5);
                }

                &:after {
                    background-color: #191919;
                    box-shadow: inset 0 0 0 2px #444444;
                }
            }
        }

        .header {
            min-height: 30px;
            max-height: 30px;
            text-align: left;
            color: #777;
            font-weight: 500;
            background-color: #fafafa;
            border-bottom: 2px solid #ccc;
            padding: 3px 6px;
            flex: 1;

            > .dropdown {
                float: right;
            }

            > .dropdown ::v-deep .btn,
            .btn {
                background: transparent;
                border: none;
                color: #555;
                line-height: 26px;
                width: 26px;
                height: 26px;
                top: -1px;
                right: -5px;
                padding: 0;
                border-radius: 0;

                .fas {
                    font-size: 14px;
                }

                &:hover {
                    background-color: rgba(0, 0, 0, 0.1);
                }
                &:active {
                    background-color: rgba(0, 0, 0, 0.2);
                }
            }
        }

        .content {
            flex: 20;
            height: 100%;
            overflow-y: auto;
            margin: 0;
            padding: 0;
        }

        &.iframe-mode {
            .content {
                overflow: hidden;

                iframe {
                    border: none;
                    width: 100%;
                    height: 100%;
                    margin: 0;
                    padding: 0;
                }
            }
        }
    }
</style>
