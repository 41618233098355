<template>
    <component :is="dynamicComponent" v-bind="$props" v-on="$listeners"/>
</template>

<script>

// properties-block-selector #hot-reload-debug
export default {
    name: `properties-block-selector`,
    props: { // props should be identical to properties-block
        value: {type: Object, required: true},
        blockSelection: {type: Array},
        isChild: {type: Boolean},
        readonly: {type: Boolean},
        noSlide: {type: Boolean},
        index: {type: Number},
        count: {type: Number}
    },
    computed: {
        dynamicComponent() {
            if (this.value) {
                return () => import(`@/components/editor/properties/blocks/properties-block-${this.value.type}.vue`);
            } else {
                return () => import(`@/components/editor/properties/blocks/properties-block.vue`);
            }
        }
    }
}
</script>

<style lang="scss">
</style>
