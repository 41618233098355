<template>
    <i class="e-hint fas fa-question-circle" v-b-popover.hover="tr(text)"></i>
</template>

<script>
    export default {
        name: `e-hint`,
        props: {
            text: { type: [Object, String], required: true }
        }
    }
</script>

<style lang="scss" scoped>
    .e-hint {
        opacity: 0.6;
        transition: opacity 0.2s;
        cursor: help;

        &:hover {
            opacity: 1;
        }
    }
</style>
