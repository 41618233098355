<template>
    <div style="display: contents">
        <b-input ref="phone" :value="internalValue" @change="onInput" :state="state" class="e-tel-input" autocomplete="phone" :readonly="readonly" :maxlength="maxlength"></b-input>
        <template v-if="showFeedback">
            <div class="text-danger f-10 mt-1 ml-1" v-if="required && !value">
                <span v-tr>Phone number required|Numéro de téléphone requis</span>
            </div>
            <div class="text-danger f-10 mt-1 ml-1" v-else-if="value && !state">
                <span v-tr>Invalid phone number|Numéro de téléphone invalide</span>
            </div>
        </template>
    </div>
</template>

<script>
    import intlTelInput from 'intl-tel-input';
    import 'intl-tel-input/build/css/intlTelInput.css';

    // intl-tel-input docs: https://github.com/jackocnr/intl-tel-input

    export default {
        name: `e-tel-input`,
        props: {
            value: { type: String },
            defaultCountry: { type: String, default: `CA` },
            preferredCountries: { type: Array, default: _ => [`CA`, `FR`, `US`, `GB`] },
            required: { type: Boolean, default: false },
            showDialCode: { type: Boolean, default: false },
            showFeedback: { type: Boolean, default: false },
            readonly: { type: Boolean, default: false },
            maxlength: { type: [Number, String] }
        },
        data() {
            return {
                instance: null,
                state: false,
                internalValue: null
            }
        },
        computed: {
            displayFormat() {
                return this.showDialCode ? intlTelInputUtils.numberFormat.NATIONAL : intlTelInputUtils.numberFormat.INTERNATIONAL;
            },
            storeFormat() {
                return intlTelInputUtils.numberFormat.E164;
            }
        },
        async created() {
            this.init();
        },
        destroyed() {
            this.instance.destroy();
        },
        methods: {
            async onInput(event) {
                this.instance.setNumber(event);
                await this.$nextTick();

                // Emit formatted value ready to be store in db e.g: +15145433445
                this.$emit(`input`, this.instance.getNumber(this.storeFormat));

                this.internalValue = this.instance.getNumber(this.storeFormat);
                this.instance.setNumber(this.internalValue);

                // setNumber on instance takes 1 frame to proceed, you have to wait for it to update internal value
                await this.$nextTick();
                this.internalValue = this.instance.getNumber(this.displayFormat);

                this.state = this.instance.isValidNumber();
                if (!this.required && !this.value)
                    this.state = null;

                this.$forceUpdate();
            },
            isValid() {
                // Made for external calls
                return this.state === null || !this.state;
            },
            async init() {
                if (this.instance) {
                    this.instance.destroy();
                }

                await this.$nextTick();
                const element = this.$refs.phone.$refs.input;

                this.instance = intlTelInput(element, {
                    initialCountry: this.defaultCountry,
                    nationalMode: true,
                    separateDialCode: this.showDialCode,
                    utilsScript: require(`intl-tel-input/build/js/utils.js`),
                    formatOnDisplay: true,
                    preferredCountries: this.preferredCountries
                });

                // Await utils script to load
                await this.instance.promise;

                if (this.value) {
                    this.instance.setNumber(this.value);
                    this.internalValue = this.instance.getNumber(this.displayFormat);
                }

                this.state = this.instance.isValidNumber();
                if (!this.required && !this.value)
                    this.state = null;

                element.addEventListener(`countrychange`, () => {
                    this.state = this.instance.isValidNumber();
                    if (!this.required && !this.value)
                        this.state = null;

                    this.$emit(`country-change`, this.instance.getSelectedCountryData().iso2);
                });

                // Fix a weird glitch that screw up input padding when flags are not loaded yet
                const observer = new ResizeObserver(() => element.style.paddingLeft = `${this.instance.selectedFlag.offsetWidth + 6}px`);
                observer.observe(this.instance.selectedFlag);
            }
        }
    }
</script>

<style lang="scss">

    .iti__flag {
        background-image: url("~intl-tel-input/build/img/flags.png");
    }

    .iti__selected-dial-code {
        font-weight: 500;
    }

    .iti__country-list {
        position: fixed;
    }

    .iti {
        width: 100%;
    }

</style>
