<template>
    <b-list-group-item class="navigation-link-list-view-item interactive" @click="$emit('click', link)">
        <i class="drag-handle fas fa-grip-dots-vertical fa-fw mr-1 display-hover" :title="'Drag to reorder|Glissez pour modifier l\'ordre'|tr"/>
        <i class="fa-fw mr-1 hide-hover" :class="[ fontAwesomePrefix, link.glyph || 'fa-link']"/>
        <div class="overview" style="line-height: 1.2">
            <div v-if="linkType" class="type">{{linkType.label|tr}}</div>
            <span class="name f-10" v-if="link.name">{{link.name|tr}}</span>
            <span class="url f-9" v-else>{{link.url|tr}}</span>
        </div>
        <template v-if="!readonly">
            <e-button-confirm @click="$emit('delete')" variant="danger" class="btn-delete display-hover" button-class="btn-danger"
                              confirm-title="Delete?|Supprimer?" button-text="Delete|Supprimer" :icon="getPublicIcon('trash')" :title="'Delete|Supprimer'|tr" v-b-tooltip>
                <i class="fas fa-trash"/>
            </e-button-confirm>
        </template>
        <b-img width="40" height="40" rounded class="list-thumbnail ml-auto" v-if="imageSrc" :blank="!imageSrc" blank-color="#eee" :src="imageSrc"/>
    </b-list-group-item>
</template>

<script>
import CmsLink from "@/helpers/cms/CmsLink.js";

// navigation-link-list-view-item #hot-reload-debug
import EButtonConfirm from "../../../../vue-components/components/e-button-confirm.vue";

export default {
    name: `navigation-link-list-view-item`,
    components: {EButtonConfirm},
    props: {
        link: {type: Object, required: true},
        readonly: {type: Boolean}
    },
    data() {
        return {
            linkTypes: CmsLink.linkTypes
        }
    },
    computed: {
        imageSrc() {
            return this.link.value && this.link.value.thumbnail;
        },
        linkType() {
            return this.linkTypes.find(t => t.value === this.link.type);
        }
    }
}
</script>

<style lang="scss" scoped>
.navigation-link-list-view-item {
    padding: 10px;
    display: flex;
    align-items: center;
    position: relative;
    height: 62px;

    .overview {
        display: inline-flex;
        flex-direction: column;
        justify-content: center;
        flex: 1;
        height: 100%;
        overflow: hidden;
        font-size: 10pt;
    }

    .type {
        font-weight: 500;
        margin-bottom: 3px;
    }

    .name, .url {
        font-size: 10pt;
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
    }

    .drag-handle {
        cursor: move; /* fallback if grab cursor is unsupported */
        cursor: grab;
    }

    .btn-duplicate {
        position: absolute;
        right: 60px;
        top: 10px;
    }

    .btn-delete {
        position: absolute;
        right: 10px;
        top: 10px;
    }

    .display-hover {
        display: none;
    }

    .list-thumbnail {
        object-fit: cover;
        border: 2px solid #ccc;
    }

    &:hover {
        .hide-hover {
            display: none;
        }

        .display-hover {
            display: inline-block;
        }
    }
}
</style>
