<template>
    <editor-sidebar class="properties-website-navigation" floating :visible="visible">
        <template #header>
            <b-btn variant="light" class="mr-2" @click="$emit('close')" :title="'Cancel|Annuler'|tr">
                <i class="fas fa-arrow-left"/>
            </b-btn>
            <h4 class="sidebar-title my-2" v-tr>Menu|Menu</h4>
            <b-btn @click="$emit(`close`)" class="ml-auto" variant="primary">
                <span v-tr>Apply|Appliquer</span>
            </b-btn>
        </template>

        <b-alert show variant="white" class="text-center">
            <div class="f-500 text-primary" v-tr>
                The Menu applies to the entire website|Le menu s'applique à tout le site
            </div>
        </b-alert>

        <sidebar-group title="Navigation">
            <b-card no-body>
                <vuedraggable v-model="currentWebsite.menu.links" class="list-group" handle=".drag-handle"
                              ghost-class="list-item-ghost" drag-class="list-item-drag" animation="150" @start="drag = true">
                    <navigation-link-list-view-item v-for="(link, index) in currentWebsite.menu.links" :link="link" :key="'link-' + index" @click="selectLink(link, index)"
                                          @delete="deleteLink(index)"/>
                </vuedraggable>
            </b-card>

            <b-btn variant="primary" class="mt-3" block @click="addLink">
                <i class="fas fa-plus mr-1"/>
                <span v-tr>Add Link|Ajouter lien</span>
            </b-btn>
        </sidebar-group>

        <link-picker v-if="pickerActive" v-model="link" :visible.sync="pickerVisible" @apply="apply" customizable/>

    </editor-sidebar>
</template>

<script>
import BlockListViewItem from "@/components/editor/properties/block-list-view-item.vue";
import SidebarGroup from "@/components/editor/properties/components/sidebar-group.vue";
import BtnColorScheme from "@/components/editor/properties/inputs/btn-color-scheme.vue";
import InputColor from "@/components/editor/properties/inputs/input-color.vue";
import InputImage from "@/components/editor/properties/inputs/input-image.vue";
import LinkPicker from "@/components/editor/properties/inputs/link-picker.vue";
import SelectCmsItemType from "@/components/editor/properties/inputs/select-cms-item-type.vue";
import NavigationLinkListViewItem from "@/components/editor/properties/navigation-link-list-view-item.vue";
import CmsLink from "@/helpers/cms/CmsLink.js";

// properties-website-navigation #hot-reload-debug
import EditorSidebar from "@/layout/editor-sidebar.vue";
import Vuedraggable from "vuedraggable";

export default {
    name: `properties-website-navigation`,
    components: {BtnColorScheme, InputColor, SidebarGroup, InputImage, LinkPicker, NavigationLinkListViewItem, SelectCmsItemType, Vuedraggable, BlockListViewItem, EditorSidebar},
    props: {
        visible: {type: Boolean},
        value: {type: Object, required: true}
    },
    data() {
        return {
            drag: false,
            pickerActive: false,
            pickerVisible: false,
            indexSelected: null,
            linkSelected: null,
            link: null
        }
    },
    computed: {
        currentWebsite: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit(`input`, value);
            }
        }
    },
    methods: {
        selectLink(link, index) {
            this.linkSelected = link;
            this.indexSelected = index;
            this.link = new CmsLink(this.linkSelected);
            this.pickerActive = true;
            this.pickerVisible = true;
        },
        addLink() {
            this.linkSelected = null;
            this.indexSelected = null;
            this.link = new CmsLink();
            this.pickerActive = true;
            this.pickerVisible = true;
        },
        deleteLink(index) {
            this.currentWebsite.menu.links.removeItemAtIndex(index);
        },
        apply(link) {
            if (this.indexSelected !== null) {
                this.$set(this.currentWebsite.menu.links, this.indexSelected, link);
            } else {
                this.currentWebsite.menu.links.push(link);
            }
            this.linkSelected = link;
        }
    }
}
</script>

<style lang="scss" scoped>
</style>
