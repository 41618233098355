<template>
    <editor-sidebar class="properties-new-block" floating :visible="visible" body-class="overflow-auto">
        <template #header>
            <b-btn variant="light" class="mr-2" @click="$emit('close')" :title="'Cancel|Annuler'|tr">
                <i class="fas fa-close"/>
            </b-btn>
            <h4 class="sidebar-title" v-tr>Add Section|Ajouter section</h4>
        </template>
        <div v-for="(category, index) in categories" :key="`category-${index}`">
            <h4>{{category.label|tr}}</h4>
            <b-card no-body>
                <b-list-group class="page-blocks">
                    <b-list-group-item v-for="(preset, index) in category.types" :key="'preset-' + index" @click="$emit('create-block', preset)" class="interactive">
                        <i class="fas fa-fw mr-2" :class="preset.typeDef.glyph"/>
                        <span>{{preset.typeDef.name|tr}}</span>
                    </b-list-group-item>
                </b-list-group>
            </b-card>
        </div>

        <btn-checkbox v-if="childOnly && (!childClass || childClass.length === 0)" v-model="showAll" class="mt-3 w-100">
            <span v-tr>Show All|Tout afficher</span>
        </btn-checkbox>
    </editor-sidebar>
</template>

<script>
import BtnCheckbox from "@/components/editor/properties/inputs/btn-checkbox.vue";

// properties-new-block #hot-reload-debug
import EditorSidebar from "@/layout/editor-sidebar.vue";

export default {
    name: `properties-new-block`,
    components: {BtnCheckbox, EditorSidebar},
    props: {
        visible: {type: Boolean},
        childClass: {type: Array},
        childOnly: {type: Boolean}
    },
    data() {
        return {
            showAll: false
        }
    },
    computed: {
        typesFiltered() {
            if (this.childOnly) {
                if (this.childClass && this.childClass.length > 0) {
                    return this.childClass;
                } else {
                    if (this.showAll) {
                        return window.cmsFactory.constructors.filter(c => !c.typeDef.hidden);
                    } else {
                        return window.cmsFactory.constructors.filter(c => !c.typeDef.isParent && !c.typeDef.hidden);
                    }
                }
            } else {
                return window.cmsFactory.constructors.filter(c => !c.typeDef.hidden && (!c.typeDef.cmsItem || this.cmsPage.itemType));
            }
        },
        categories() {
            let types = {
                preset: {
                    label: `Template|Gabarit`,
                    types: [],
                },
                base: {
                    label: `Base|Base`,
                    types: [],
                },
                layout: {
                    label: `Layout|Disposition`,
                    types: [],
                },
                widget: {
                    label: `Widgets|Widgets`,
                    types: [],
                }
            };
            for (let typeClass of this.typesFiltered) {
                types[typeClass.typeDef.category].types.push(typeClass);
            }

            return types;
        }
    }
}
</script>

<style lang="scss" scoped>
.page-blocks {

}
</style>
