<template>
    <b-btn v-if="showDesktop || previewMode !== `desktop`" variant="transparent" size="sm" class="btn-responsive-prop"
           :class="{'no-label': noLabel, 'for-checkbox': forCheckbox, 'not-inline': notInline}">
        <i @click.prevent="toggle" class="fa-fw fas" :class="[isEnabled ? 'text-primary fa-hexagon-check' : 'text-gray fa-hexagon']" :title="title"/>
    </b-btn>
</template>

<script>

// btn-responsive-prop #hot-reload-debug
export default {
    name: `btn-responsive-prop`,
    props: {
        value: {},
        field: {type: String, required: true},
        showDesktop: {type: Boolean, default: false},
        noLabel: {type: Boolean},
        notInline: {type: Boolean},
        forCheckbox: {type: Boolean}
    },
    computed: {
        target: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit(`input`, value);
            }
        },
        propValue: {
            get() {
                if (this.displayMode === `desktop`) {
                    return this.target[this.field];
                } else {
                    return this.target.mediaQuery[this.displayMode][this.field];
                }
            },
            set(value) {
                if (this.displayMode === `desktop`) {
                    this.$set(this.target, this.field, value);
                } else {
                    this.$set(this.target.mediaQuery[this.displayMode], this.field, value);
                }
            }
        },
        defaultValue() {
            return this.target[this.field];
        },
        isEnabled() {
            return this.target && !isNullOrUndefined(this.propValue);
        },
        previewIcon() {
            switch (this.displayMode) {
                case `mobile`:
                    return `fas fa-mobile-iphone`;
                case `tablet`:
                    return `fas fa-tablet-screen`;
                case `kiosk`:
                    return `fak fa-kiosk`;
                default:
                    return `fas fa-desktop`;
            }
        },
        title() {
            let device = `Desktop|Ordinateur`;
            switch (this.displayMode) {
                case `mobile`:
                    device = `Mobile`;
                    break;
                case `tablet`:
                    device = `Tablet|Tablette`;
                    break;
                case `kiosk`:
                    device = `Kiosk|Kiosque`;
                    break;
            }
            device = this.tr(device);
            return this.tr(`When checked, this value will be different for ${device}|Si cochée, cette valeur sera différente en mode ${device}`);
        }
    },
    methods: {
        toggle() {
            if (!this.isEnabled) {
                this.propValue = this.defaultValue;
            } else {
                this.propValue = undefined;
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.btn-responsive-prop {
    position: absolute;
    padding: 0;
    height: 26px;
    left: -20px;
    top: 7px;

    &.for-checkbox,
    &.no-label {
        left: -24px;
    }

    &.for-checkbox {
        top: 4px;
    }

    &.not-inline {
        top: 35px;
    }
}

.sub-group {
    .btn-responsive-prop {
        left: -30px;

        &.not-inline,
        &.for-checkbox,
        &.no-label {
            left: -35px;
        }

        &.for-checkbox {
            top: 0;
        }

        &.not-inline {
            top: 35px;
        }
    }
}
</style>
