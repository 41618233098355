<template>
    <b-list-group-item class="block-list-view-item interactive" @click="$emit('click', block)">
        <i class="drag-handle fas fa-grip-dots-vertical fa-fw mr-1 display-hover" :title="'Drag to reorder|Glissez pour modifier l\'ordre'|tr"/>
        <i class="fas fa-fw mr-1 hide-hover" :class="block.typeDef.glyph"/>
        <span class="name">
            {{block.displayName|tr}}
        </span>
        <template v-if="!readonly">
            <b-btn @click.stop="$emit('duplicate')" variant="white" class="btn-duplicate display-hover" :title="'Duplicate|Dupliquer'|tr" v-b-tooltip><i class="fas fa-copy"/></b-btn>
            <e-button-confirm @click="$emit('delete')" variant="danger" class="btn-delete display-hover" button-class="btn-danger"
                              confirm-title="Delete?|Supprimer?" button-text="Delete|Supprimer" :icon="getPublicIcon('trash')" :title="'Delete|Supprimer'|tr" v-b-tooltip>
                <i class="fas fa-trash"/>
            </e-button-confirm>
        </template>
        <i class="list-glyph ml-auto" :class="[fontAwesomePrefix, glyph]" v-if="glyph"/>
        <b-img width="40" height="40" rounded class="list-thumbnail ml-auto" v-if="imageSrc" :blank="!imageSrc" blank-color="#eee" :src="imageSrc"/>
    </b-list-group-item>
</template>

<script>
// block-list-view-item #hot-reload-debug
import EButtonConfirm from "../../../../vue-components/components/e-button-confirm.vue";

export default {
    name: `block-list-view-item`,
    components: {EButtonConfirm},
    props: {
        block: {type: Object, required: true},
        readonly: {type: Boolean}
    },
    computed: {
        imageSrc() {
            return this.block.thumbnail || this.block.image?.languageProxy.src;
        },
        glyph() {
            return this.block.glyph?.glyph;
        }
    }
}
</script>

<style lang="scss" scoped>
.block-list-view-item {
    padding: 10px;
    display: flex;
    align-items: center;
    position: relative;
    height: 62px;

    .btn-duplicate {
        position: absolute;
        right: 60px;
        top: 10px;
    }

    .btn-delete {
        position: absolute;
        right: 10px;
        top: 10px;
    }
}
</style>
