<template>
    <b-img class="virtual-assistant-avatar" src="https://eyeinlivestorage.blob.core.windows.net/public/eye-intelligence/virtual-assistant/virtual-assistant.png">
    </b-img>
</template>

<script>
    export default {
        name: `virtual-assistant-avatar`
    }
</script>

<style lang="scss" scoped>
.virtual-assistant-avatar {
    display: inline-block;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: white;
    border: 1px solid rgba(black, 0.2);
    //padding: 2px;

    transition: 250ms;
}
.b-popover {
    .virtual-assistant-avatar {
        margin-top: -15px;
        margin-left: -15px;
        width: 60px;
        height: 60px;
        margin-right: 5px;
    }
}
</style>
