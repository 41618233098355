<template>
    <vue-select v-model="field" @input="valueChanged" :reduce="t => t.id" label="label" :clearable="false" :options="options" :append-to-body="appendToBody">
        <template #open-indicator>
            <i class="ml-auto fas fa-caret-down"></i>
        </template>
        <template v-slot:selected-option="option">
            <i class="fa-fw float-left fas mr-1" :class="option.glyph"/>
            <div>{{ option.label }}</div>
        </template>
        <template v-slot:option="option">
            <i class="fa-fw float-left fas mr-1" :class="option.glyph"/>
            <div>{{ option.label }}</div>
        </template>
    </vue-select>
</template>

<script>
import FieldsHelper from "@/helpers/cms/FieldsHelper.js";
import VueSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';

// select-cms-item-field #hot-reload-debug
export default {
    name: `select-cms-item-field`,
    components: {VueSelect},
    props: {
        value: {type: String},
        itemType: {type: String, required: true},
        fieldType: {type: String, default: `all`}, // any-text | any-link | field-type
        appendToBody: {type: Boolean}
    },
    data() {
        return {
            FieldsHelper
        }
    },
    computed: {
        field: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit(`input`, value);
            }
        },
        itemTypeComputed() {
            if (this.itemType === `inherit`) {
                return this.cmsPage.itemType;
            } else {
                return this.itemType;
            }
        },
        typeDef() {
            return this.cms.getTypeById(this.itemTypeComputed);
        },
        options() {
            let options = this.getOptions(this.typeDef, null);
            if (this.typeDef && this.typeDef.linkedItems) {
                for (let key in this.typeDef.linkedItems) {
                    const childTypeDef = this.cmsTypes.find(t => t.type === key);
                    if (childTypeDef) {
                        const childOptions = this.getOptions(childTypeDef, key);
                        options = options.concat(childOptions);
                    }
                }
            }
            return options;
        }
    },
    methods: {
        reducer(item) {
            return {
                id: item.id,
                type: item.type
            }
        },
        valueChanged(value) {
            if (value) {
                return;
            }
            const fieldDef = FieldsHelper.getField(value);
            if (fieldDef.formats) {
                this.$emit(`format-change`, fieldDef.formats[0].value);
            } else {
                this.$emit(`format-change`, null);
            }
        },
        getOptions(typeDef, rootKey = null) {
            let options = [];
            if (rootKey === null) {
                options.push({
                    id: null,
                    label: this.tr(`Manual|Manuel`),
                    glyph: `fa-pen-field`
                });
            }
            let fields;
            if (this.fieldType !== `all`) {
                const fieldTypes = FieldsHelper.filterTypes(this.fieldType).map(f => f.type);
                fields = typeDef.fields.filter(f => !f.hidden && fieldTypes.includes(f.type));
            } else {
                fields = typeDef.fields.filter(f => !f.hidden);
            }
            for (let field of fields) {
                options.push({
                    id: field.id,
                    label: this.tr(field.name) || field.id,
                    glyph: FieldsHelper.getField(field.type).glyph
                })
            }
            if ([`all`, `any-link`, `text`].includes(this.fieldType)) {
                options.push({
                    id: `self-link`,
                    label: this.tr(`Item Link|Lien vers l'élément`),
                    glyph: `fa-link`
                });
            }
            if ([`all`, `any-link`].includes(this.fieldType)) {
                options.push({
                    id: `map-link`,
                    label: this.tr(`View on map|Voir sur le plan`),
                    glyph: `fa-map-location-dot`
                });
            }
            if (typeDef.secondaryField && [`all`, `any-text`, `text`].includes(this.fieldType)) {
                options.push({
                    id: `secondaryField`,
                    label: this.tr(`Subtitle|Sous-titre`),
                    glyph: `fa-heading`
                });
            }
            if (typeDef.previewImage && [`all`, `image`].includes(this.fieldType)) {
                options.push({
                    id: `previewImage`,
                    label: this.tr(`Preview Image|Image d'aperçu`),
                    glyph: `fa-image`
                });
            }
            if (rootKey) {
                for (let option of options) {
                    option.id = `${rootKey}.${option.id}`;
                    option.label = `${this.tr(typeDef.name)} / ${option.label}`;
                }
            }
            return options;
        }
    }
}
</script>

<style lang="scss" scoped>
</style>
