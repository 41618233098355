<template>
    <div class="e-color-picker" :class="{ 'inline': inline }">
        <template v-if="button">
            <b-btn class="btn-color-picker" :variant="variant" :size="buttonSize" :class="{'btn-squared': buttonSquared}" @click="showOverlay" :style="style">
                <slot></slot>
            </b-btn>
        </template>
        <label v-else @click="showOverlay">
            {{label}}
            <b-input type="text" v-model="value" :style="style"></b-input>
        </label>
        <div class="picker-overlay" v-show="overlayVisible" @click="overlayClicked">
            <div class="color-picker">
                <sketch-picker v-if="type === 'sketch'" :value="color" @input="colorChanged" @ok="overlayVisible = false" @cancel="cancel"></sketch-picker>
                <photoshop-picker v-else :value="color" @input="colorChanged" @ok="overlayVisible = false" @cancel="cancel"></photoshop-picker>
            </div>
        </div>
    </div>
</template>

<script>
    import {Photoshop, Sketch} from 'vue-color'

    export default {
        name: `e-color-picker`,
        props: {
            value: {},
            button: {type: Boolean},
            variant: {type: String, default: `light`},
            buttonSize: {type: String, default: `md`},
            inline: {type: Boolean},
            buttonSquared: {type: Boolean},
            type: {type: String, default: `photoshop`},
            label: {type: String}
        },
        components: {
            'photoshop-picker': Photoshop,
            'sketch-picker': Sketch
        },
        data() {
            return {
                overlayVisible: false,
                rememberedColor: {},
                color: {
                    hex: `#3080C9`,
                    rgba: {
                        r: 0,
                        g: 0,
                        b: 0
                    }
                }
            }
        },
        computed: {
            textColor() {
                let textColor = `black`;

                let luminance = (0.299 * this.color.rgba.r + 0.587 * this.color.rgba.g + 0.114 * this.color.rgba.b) / 255;
                if (luminance <= 0.5) // dark colors - white font
                    textColor = `white`;

                return textColor;
            },
            style() {
                return {
                    background: this.color.hex + ` !important`,
                    color: this.textColor + ` !important`
                }
            }
        },
        mounted() {
            if (this.value) {
                this.color.hex = this.value;
                this.color.rgba = this.hexToRgb(this.color.hex);
            }

            this.$emit(this.color.hex);
        },
        methods: {
            overlayClicked() {
                if (this.type === `sketch`) {
                    this.overlayVisible = false;
                }
            },
            colorChanged(colorObject) {
                this.color = colorObject;
                this.$emit(`input`, colorObject.hex);
                this.$emit(`change`, colorObject.hex);
            },
            showOverlay(event) {
                this.rememberedColor = this.color;
                this.overlayVisible = true;
            },
            cancel() {
                this.color = this.rememberedColor;
                this.$emit(this.color.hex);
                this.overlayVisible = false;
            },
            hexToRgb(hex) {
                var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
                return result ? {
                    r: parseInt(result[1], 16),
                    g: parseInt(result[2], 16),
                    b: parseInt(result[3], 16)
                } : { r: 0, g: 0, b: 0 };
            }
        },
        watch: {
            value(value) {
                if (this.value) {
                    this.color.hex = this.value;
                    this.color.rgba = this.hexToRgb(this.color.hex);
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
.btn-color-picker.btn-squared {
    width: 32px;
    height: 32px;
}

.e-color-picker {
    &.inline {
        display: inline-flex;
    }

    label {
        margin-bottom: 0;
    }
}

    .picker-overlay {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1000;
        background-color: rgba(0, 0, 0, 0.5);

        .color-picker {
            position: fixed;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);

            .vc-photoshop {
                font-family: inherit;

                .vc-ps-fields .vc-input__label,
                .vc-ps-fields .vc-input__desc {
                    color: #000;
                }
            }
        }
    }
</style>
