<template>
    <editor-sidebar class="properties-website-style" floating has-tabs :visible="visible">
        <template #header>
            <b-btn variant="light" class="mr-2" @click="close" :title="'Cancel|Annuler'|tr">
                <i class="fas fa-arrow-left"/>
            </b-btn>
            <h4 class="sidebar-title my-2" v-tr>Website Style|Style du site</h4>
            <b-btn @click="applyAndClose()" class="ml-auto" variant="primary">
                <span v-tr>Apply|Appliquer</span>
            </b-btn>
        </template>

        <div class="p-3 text-left">
            <sidebar-group title="Branding|Identité visuelle">
                <b-form-group>
                    <b-btn @click="setMode(MODES.COLORS)" block variant="outline-white">
                        <span class="left theme-colors">
                            <input-color-preview class="theme-color-primary" size="sm" :color="currentWebsite.design.colors.primary"/>
                            <input-color-preview class="theme-color-secondary" size="sm" :color="currentWebsite.design.colors.secondary"/>
                        </span>
                        <span v-tr>Colors|Couleurs</span>
                    </b-btn>
                </b-form-group>

                <b-form-group>
                    <b-btn @click="setMode(MODES.FONTS)" block variant="outline-white">
                        <span class="left">
                            <i class="fas fa-font-case fa-fw"/>
                        </span>
                        <div>
                            <div class="f-10" v-tr>Fonts|Polices de caractères</div>
                            {{website.design.fonts.titles.fontFamily}} -
                            {{website.design.fonts.body.fontFamily}}
                        </div>
                    </b-btn>
                </b-form-group>

                <b-form-group>
                    <b-btn @click="$emit('edit-website-settings')" block variant="outline-white">
                        <span class="left">
                            <b-img width="24" height="24" v-if="cmsWebsite.design.favicon.src" :src="cmsWebsite.design.favicon.src"/>
                            <i v-else class="fas fa-globe mr-2"/>
                        </span>
                        <div>
                            <div class="f-10" v-tr>Favicon & Logo|Favicon et logo principal</div>
                            {{website.name}}
                        </div>
                    </b-btn>
                </b-form-group>
            </sidebar-group>

            <sidebar-group title="Page">
                <b-form-group label-cols="7" :label="'Page Default Colors|Couleurs par défaut'|tr">
                    <input-color @input="applyChanges" v-model="currentWebsite.body.design.style.background" no-variants inline variant="white" :title="'Page Color|Couleur de la page'|tr"/>

                    <input-color @input="applyChanges" v-model="currentWebsite.body.design.style.color" no-variants inline class="ml-2" variant="white" :title="'Default Text Color|Couleur du texte par défaut'|tr">
                        <i class="fas fa-text"/>
                    </input-color>
                </b-form-group>

                <b-form-group label-cols="7" :label="'Max Width|Largeur maximale'|tr">
                    <b-input-group>
                        <e-input @input="applyChanges" v-model="currentWebsite.body.design.style.maxWidth" class="text-right" :min="600" :max="2500" state="disabled"/>
                        <b-input-group-text>
                            px
                        </b-input-group-text>
                    </b-input-group>
                </b-form-group>
            </sidebar-group>

            <sidebar-group title="Buttons & Shapes|Formes et boutons">
                <b-form-group :label="'Item Shapes|Formes des éléments'|tr">
                    <input-border-radius @input="applyChanges" more no-theme no-circle v-model="currentWebsite.design.borders.radius"/>
                </b-form-group>

                <b-form-group label-cols="7" :label="'Border Width|Épaisseur des bordures'|tr">
                    <b-select @input="applyChanges" v-model="currentWebsite.design.borders.width">
                        <option value="0px" v-tr>None|Sans</option>
                        <option v-for="n in 3" :key="`width-${n}`" :value="`${n}px`">{{n}}px</option>
                    </b-select>
                </b-form-group>

                <b-form-group :label="'Icon Style|Style des icônes'|tr">
                    <btn-radio class="btn-font-awesome-style" v-for="style in fontAwesomeStyles" :key="style.value" v-model="currentWebsite.design.fontAwesome.prefix" :option="style" variant="editor" :title="tr(style.label)" active-variant="editor" active-class="selected">
                        <i class="fa-web-awesome" :class="style.value"/>
                    </btn-radio>
                </b-form-group>
            </sidebar-group>

            <sidebar-group title="Accessibility|Accessibilité">
                <sidebar-group title="Wheelchair Mode|Mode handicapé" sub-group>
                    <b-form-group :label="'Offset Top|Marge en haut'|tr">
                        <e-input v-model="currentWebsite.accessibility.wheelchair.topMargin"/>
                    </b-form-group>

                    <b-form-group :label="'Background Color|Couleur de fond'|tr">
                        <input-color v-model="currentWebsite.accessibility.wheelchair.background"/>
                    </b-form-group>

                    <b-form-group :label="'Placeholder Image|Image de remplissage'|tr">
                        <input-image @input="applyChanges" v-model="currentWebsite.accessibility.wheelchair.placeholder.src"/>
                    </b-form-group>
                </sidebar-group>
            </sidebar-group>
        </div>

        <editor-sidebar :visible="mode === MODES.COLORS" @close="mode = MODES.DEFAULT" close-button floating title="Colors|Couleurs">
            <div class="p-3">
                <sidebar-group title="Colors|Couleurs">
                    <b-form-group>
                        <input-color @input="applyChanges" v-model="currentWebsite.design.colors.primary" button block no-variants variant="white" label="Primary Color|Couleur principale"/>
                    </b-form-group>
                    <b-form-group>
                        <input-color @input="applyChanges" v-model="currentWebsite.design.colors.secondary" button block no-variants variant="white" label="Secondary Color|Couleur secondaire"/>
                    </b-form-group>
                    <h4 v-tr>Other Colors|Autres couleurs</h4>
                    <b-form-group>
                        <input-color v-for="n in 8" :key="'custom' + n" @input="applyChanges" size="sm" class="mr-1 mb-1" inline v-model="currentWebsite.design.colors[`custom${n}`]" no-variants/>
                    </b-form-group>
                </sidebar-group>

                <sidebar-group title="Color Styles|Styles de couleurs">
                    <b-form-group>
                        <btn-color-scheme v-for="scheme in cmsWebsite.design.colorSchemes" :key="`scheme-${scheme.id}`" v-model="colorScheme" :color-scheme="scheme"/>
                        <btn-color-scheme @click.native="addColorScheme" v-if="cmsWebsite.design.colorSchemes.length < 8" :color-scheme="{}">
                            <i class="fas fa-plus"/>
                        </btn-color-scheme>
                    </b-form-group>

                    <template v-if="colorScheme">
                        <b-form-group label-cols="8" :label="'Background|Arrière-plan'|tr">
                            <input-color @input="applyChanges" v-model="colorScheme.background" allow-transparent/>
                        </b-form-group>

                        <b-form-group label-cols="8" :label="'Titles & Texts|Titres et Textes'|tr">
                            <input-color @input="applyChanges" v-model="colorScheme.title" inline :title="'Titles Color|Couleur des titres'|tr">
                                <i class="fas fa-heading"/>
                            </input-color>
                            <input-color @input="applyChanges" v-model="colorScheme.paragraph" inline class="ml-2" :title="'Text Color|Couleur du texte'|tr">
                                <i class="fas fa-text"/>
                            </input-color>
                        </b-form-group>

                        <b-form-group label-cols="8" label-class="m-0 pt-0">
                            <template #label>
                                <btn-button-type preset="solid"/>
                            </template>
                            <input-color @input="applyChanges" inline v-model="colorScheme.buttonBG" :title="'Background Color|Couleur de fond'|tr"/>
                            <input-color @input="applyChanges" class="ml-2" inline v-model="colorScheme.buttonColor" :title="'Text Color|Couleur du texte'|tr">
                                <i class="fas fa-text"/>
                            </input-color>
                        </b-form-group>

                        <b-form-group label-cols="8" label-class="m-0 pt-0">
                            <template #label>
                                <btn-button-type preset="outline"/>
                                <btn-button-type preset="light"/>
                            </template>
                            <input-color @input="applyChanges" inline v-model="colorScheme.outlineBG" :title="'Background Color|Couleur de fond'|tr" allow-transparent/>
                            <input-color @input="applyChanges" class="ml-2" inline v-model="colorScheme.outlineColor" :title="'Text Color|Couleur du texte'|tr">
                                <i class="fas fa-text"/>
                            </input-color>
                        </b-form-group>

                        <b-form-group label-cols="8" label-class="m-0 pt-0">
                            <template #label>
                                <btn-button-type preset="text"/>
                            </template>
                            <input-color @input="applyChanges" class="ml-2" inline v-model="colorScheme.linkColor" :title="'Link Color|Couleur des liens'|tr">
                                <i class="fas fa-underline"/>
                            </input-color>
                        </b-form-group>
                    </template>
                </sidebar-group>
            </div>
        </editor-sidebar>

        <editor-sidebar :visible="mode === MODES.FONTS" @close="mode = MODES.DEFAULT" close-button floating title="Fonts|Polices">
            <div class="p-3">
                <b-form-group :label="'Base Font Size|Taille de base des textes'|tr">
                    <b-select @input="applyChanges" v-model="cmsWebsite.design.fonts.baseSize">
                       <option v-for="n in 10" :key="`base-${n}`" :value="`${n+10}pt`">{{n+10}}pt</option>
                    </b-select>
                </b-form-group>

                <sidebar-group title="Headlines|Titre principal">
                    <input-font @apply="applyChanges()" v-model="cmsWebsite.design.fonts.headlines"/>
                </sidebar-group>
                <sidebar-group title="Titles|Titres">
                    <input-font @apply="applyChanges()" v-model="cmsWebsite.design.fonts.titles"/>
                </sidebar-group>
                <sidebar-group title="Body Text|Textes">
                    <input-font @apply="applyChanges()" v-model="cmsWebsite.design.fonts.body"/>
                </sidebar-group>
                <sidebar-group title="Buttons|Boutons">
                    <input-font @apply="applyChanges()" v-model="cmsWebsite.design.fonts.buttons"/>
                </sidebar-group>
            </div>
        </editor-sidebar>
    </editor-sidebar>
</template>

<script>
import SidebarGroup from "@/components/editor/properties/components/sidebar-group.vue";
import BtnButtonType from "@/components/editor/properties/inputs/btn-button-type.vue";
import BtnColorScheme from "@/components/editor/properties/inputs/btn-color-scheme.vue";
import BtnRadio from "@/components/editor/properties/inputs/btn-radio.vue";
import InputBorderRadius from "@/components/editor/properties/inputs/input-border-radius.vue";
import InputColorPreview from "@/components/editor/properties/inputs/input-color-preview.vue";
import InputColor from "@/components/editor/properties/inputs/input-color.vue";
import InputFont from "@/components/editor/properties/inputs/input-font.vue";
import InputImage from "@/components/editor/properties/inputs/input-image.vue";
import EditorSidebar from "@/layout/editor-sidebar.vue";
import EInput from "../../../../../vue-components/components/e-input.vue";

// properties-website-style #hot-reload-debug
export default {
    name: `properties-website-style`,
    components: {BtnRadio, EInput, InputBorderRadius, InputFont, SidebarGroup, InputImage, BtnButtonType, BtnColorScheme, InputColorPreview, InputColor, EditorSidebar},
    props: {
        value: {type: Object, required: true},
        visible: {type: Boolean},
    },
    data() {
        return {
            MODES: {
                DEFAULT: `default`,
                COLORS: `colors`,
                FONTS: `fonts`
            },
            mode: `default`,
            editColors: false,
            colorScheme: null,
            fonts: null,
            fontLoading: false,
            fontAwesomeStyles: [
                {label: `Thin|Fin`, value: `fat`},
                {label: `Light|Léger`, value: `fal`},
                {label: `Solid|Solide`, value: `fas`},
                {label: `Square|Carré`, value: `fa-sharp`}
            ]
        }
    },
    computed: {
        currentWebsite: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit(`input`, value);
            }
        }
    },
    mounted() {
        this.colorScheme = this.cmsWebsite.design.colorSchemes[0];
    },
    methods: {
        applyAndClose() {
            this.cmsWebsite.design.init = true
            this.applyChanges();
            this.close();
        },
        applyChanges() {
            this.cmsWebsite.applyStyle();
        },
        close() {
            this.$emit(`close`);
        },
        addColorScheme() {
            this.cmsWebsite.design.colorSchemes.push({
                id: generateGuid(),
                background: `#313639`,
                title: `#ffffff`,
                paragraph: `#cccccc`,
                buttonBG: `#c4b49a`,
                buttonColor: `#000000`,
                outlineBG: `#fff`,
                outlineColor: `#313639`,
                linkColor: `#eeeeee`
            })
        },
        setMode(mode) {
            this.mode = mode;
            if (this.mode === this.MODES.FONTS && !this.fonts) {
                this.loadFonts();
            }
        },
        loadFonts() {
            if (this.fontLoading) {
                return;
            }
            this.$store.dispatch(`googleFonts/getFonts`);
        }
    }
}
</script>

<style lang="scss" scoped>
.theme-colors {
    position: relative;

    .theme-color-secondary {
        position: absolute;
        left: 15px;
        top: 15px;
        width: 24px;
        height: 24px;
    }
}

.btn {
    &.btn-block {
        display: flex;
        align-items: center;
        text-align: left;
        height: 55px;

        .left {
            width: 40px;
            margin-right: 10px;
            text-align: center;
        }
    }
}

.btn-font-awesome-style {
  width: 75px;
  font-size: 24px;
}
</style>
