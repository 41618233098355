<template>
    <div class="btn-border-radius" @click="currentBorderRadius = preset.value"
         :class="{ 'selected': isSelected, 'square': preset.value === '50%'}"
         :style="{borderRadius: preset.value}">
<!--        <i class="fas fa-mountain-sun my-1"/>-->
        <span v-tr>{{preset.title|tr}}</span>
        <i class="fas fa-check floating-check-mark ml-1" v-if="isSelected"/>
    </div>
</template>

<script>
export default {
    name: `btn-border-radius`,
    props: {
        value: {type: String},
        preset: {type: Object}
    },
    computed: {
        currentBorderRadius: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit(`input`, value);
            }
        },
        isSelected() {
            return this.currentBorderRadius && this.currentBorderRadius === this.preset.value;
        }
    }
}
</script>

<style lang="scss" scoped>
.btn-border-radius {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 40px;
    width: 45px;
    background-color: white;
    border-radius: var(--border-radius);
    border: 2px solid rgba(black, 0.2);
    cursor: pointer;
    vertical-align: middle;
    text-align: center;

    & + .btn-border-radius {
        margin-left: 5px;
    }

    &.square {
        width: 40px;
    }

    span {
        font-size: 9pt;
        //text-transform: uppercase;
        //letter-spacing: 0.2px;
        opacity: 0.8;
    }

    .preview-title {
        font-size: 14px;
        font-weight: 700;
        line-height: 1;
        margin-bottom: 6px;
        text-align: center;
    }

    .preview-button {
        border-radius: 8px;
        height: 8px;
        width: 24px;
    }

    &:hover {
        border-color: var(--accent-color);
    }

    &.selected {
        transform: scale(0.95);
        border-color: var(--accent-color);
    }
}
</style>
