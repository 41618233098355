<template>
    <div class="input-border-radius">
        <slot name="prepend"></slot>
        <btn-border-radius v-for="(preset, index) in presets" :key="`radius-${index}`" v-model="currentBorderRadius" :preset="preset"/>
    </div>
</template>

<script>
import BtnBorderRadius from "@/components/editor/properties/inputs/btn-border-radius.vue";

// input-border-radius #hot-reload-debug
export default {
    name: `input-border-radius`,
    components: {BtnBorderRadius},
    props: {
        value: {type: String},
        more: {type: Boolean},
        noTheme: {type: Boolean},
        noCircle: {type: Boolean}
    },
    computed: {
        presets() {
            let presets = [];
            if (!this.noTheme) {
                presets.push({title: `Theme|Thème` , value: `var(--border-radius)`});
            }
            presets.push({title: `Square|Carré` , value: `0`});
            if (this.more) {
                presets.push({title: `X-Light|Min` , value: `3px`});
            }
            presets.push({title: `Light|Léger` , value: `5px`});
            presets.push({title: `More|Plus` , value: `8px`});
            presets.push({title: `Rounded|Arrondi` , value: `100px`});
            if (!this.noCircle) {
                presets.push({title: `Circle|Cercle` , value: `50%`});
            }
            return presets;
        },
        currentBorderRadius: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit(`input`, value);
            }
        }
    }
}
</script>

<style lang="scss" scoped>
</style>
