<template>
    <div class="input-file form-control">
        <template v-if="!currentFile">
            <b-btn variant="light-primary" @click="mediaLibraryVisible = true">
                <i class="fas fa-folder-open mr-1"/>
                <span v-tr>Add File|Ajouter fichier</span>
            </b-btn>
        </template>

        <template v-else>
            <div class="d-flex">
                <b-img class="mr-2" rounded width="40" height="40" :src="currentFile.thumbnail"/>
                <div class="overview" style="line-height: 1.2">
                    <div class="type text-uppercase">{{currentFile.type|tr}}</div>
                    <span class="name f-10">{{currentFile.name|tr}}</span>
                </div>
                <b-btn variant="light" @click="mediaLibraryVisible = true" class="ml-2" :title="'Replace Image with another|Remplace l\'image par une autre'|tr">
                    <span v-tr>Replace|Remplacer</span>
                </b-btn>
                <b-btn v-if="!noClear" variant="light" @click="removeFile()" class="ml-2 text-danger" :title="'Remove Image|Enlever l\image'|tr">
                    <i class="fas fa-trash"/>
                </b-btn>
            </div>
        </template>

        <e-file-library v-model="currentFile" :visible.sync="mediaLibraryVisible" return-object :category="category" source="cms-website" :source-id="cmsWebsite.id"/>
    </div>
</template>

<script>
import EFileLibrary from "../../../../../vue-components/components/e-file-library.vue";

// input-file #hot-reload-debug
export default {
    name: `input-file`,
    components: {EFileLibrary},
    props: {
        value: {type: String},
        noClear: {type: Boolean},
        category: { type: String, default: `doc` }
    },
    computed: {
        currentFile: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit(`input`, value);
            }
        }
    },
    data() {
        return {
            mediaLibraryVisible: false
        }
    },
    methods: {
        selectFile(currentFile) {
            this.currentFile = currentFile;
        },
        removeFile() {
            this.currentFile = null;
        }
    }
}
</script>

<style lang="scss" scoped>
.input-file {
    padding: 4px;
    height: 52px;

    .d-flex {
        height: 100%;
        align-items: center;

        .overview {
            display: inline-flex;
            flex-direction: column;
            justify-content: center;
            flex: 1;
            height: 100%;
            overflow: hidden;
            font-size: 10pt;
        }

        .type {
            font-weight: 500;
            margin-bottom: 3px;
        }

        .name, .url {
            font-size: 10pt;
            width: 100%;
            overflow: hidden;
            white-space: nowrap;
        }
    }
}
</style>
