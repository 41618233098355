<template>
    <b-modal class="link-picker" v-model="pickerVisible" :title="'Select Link|Choisir un lien'|tr" @hide="$emit('reset')" no-close-on-backdrop>
        <b-form-group :label="'Link to|Lien vers'|tr">
            <b-select v-model="linkValue.type" @change="onLinkTypeChanged">
                <option v-for="type in linkTypes" :key="`link-${type.value}`" :value="type.value">
                    {{type.label|tr}}
                </option>
            </b-select>
        </b-form-group>

        <b-form-group v-if="linkValue.type === 'page'" :label="'Select the page|Sélectionnez la page'|tr">
            <vue-select v-if="cmsPages" v-model="linkValue.value" @input="onChangedPage" :reduce="reducePage" :options="cmsPages" :clearable="false">
                <template #open-indicator>
                    <i class="ml-auto fas fa-caret-down"></i>
                </template>
                <template v-slot:selected-option="option">
                    <i class="fa-fw float-left fas mr-1" :class="option.glyph"/>
                    <div>{{ option.name | tr }}</div>
                </template>
                <template v-slot:option="option">
                    <i class="fa-fw float-left fas mr-1" :class="option.glyph"/>
                    <div>{{ option.name | tr }}</div>
                </template>
            </vue-select>
        </b-form-group>

        <b-form-group v-if="linkValue.type === 'mailto'" :label="'Enter or paste an Email here|Entrez ou collez une adresse courriel ici'|tr">
            <b-input-group>
                <b-input-group-text><i class="fas fa-envelope"/></b-input-group-text>
                <e-input v-model="linkValue.value" type="email" :placeholder="'Email|Courriel'|tr"/>
            </b-input-group>
        </b-form-group>

        <b-form-group v-if="linkValue.type === 'tel'" :label="'Enter or paste a Phone Number here|Entrez ou collez un numéro de téléphone ici'|tr">
            <e-tel-input v-model="linkValue.value"/>
        </b-form-group>

        <template  v-if="linkValue.type === 'item'">
            <b-form-group :label="'Select item below|Sélectionnez l\'élément ci-dessous'|tr">
                <select-cms-item v-model="linkValue.value" @input="onChangedItem" use-store/>
            </b-form-group>

            <b-form-group v-if="linkValue.type === 'item'" :label="'Select item below|Sélectionnez l\'élément ci-dessous'|tr">
                <select-cms-item v-model="linkValue.value" @input="onChangedItem" use-store/>
            </b-form-group>
        </template>

        <template v-if="linkValue.type === 'file'">
            <b-form-group :label="'Select file below|Sélectionnez un fichier ci-dessous'|tr">
                <input-file v-model="linkValue.value" no-clear/>
            </b-form-group>
        </template>

        <template v-if="linkValue.type === 'external'">
            <b-form-group :label="'Enter or paste the URL here|Entrez ou collez une URL ici'|tr">
                <e-input type="url" v-model="linkValue.value"/>
            </b-form-group>
            <b-form-group>
                <b-checkbox v-model="linkValue.openInNewTab">
                    <span v-tr>Open in new tab|Ouvrir dans un nouvel onglet</span>
                </b-checkbox>
            </b-form-group>
        </template>

        <template v-if="customizable">
            <b-checkbox v-model="linkValue.customize">
                <span v-tr>Customize|Personnaliser</span>
            </b-checkbox>
            <div class="mt-2" :class="{'disabled': !linkValue.customize}">
                <e-translation-input label="Display Name|Nom à afficher" v-model="linkValue.name"/>
                <b-form-group>
                    <input-glyph title="Link Icon|Icône du lien" v-model="linkValue.glyph"/>
                </b-form-group>
            </div>
        </template>

        <b-form-group :label="tr('Visibility|Visibilité')">
            <template v-if="linkValue.mediaQuery">
                <btn-checkbox class="mr-1" v-model="linkValue.mediaQuery.desktop.visible" :title="'Select if visible on desktop device|Visibilité sur ordinateur'|tr">
                    <i class="fas fa-desktop" style="font-size: 16pt;"/>
                </btn-checkbox>
                <btn-checkbox class="mr-1" v-model="linkValue.mediaQuery.tablet.visible" :title="'Select if visible on tablet device|Visibilité sur tablette'|tr">
                    <i class="fas fa-tablet-screen" style="font-size: 16pt;"/>
                </btn-checkbox>
                <btn-checkbox class="mr-1" v-model="linkValue.mediaQuery.mobile.visible" :title="'Select if visible on mobile device|Visibilité sur mobile'|tr">
                    <i class="fas fa-mobile-iphone" style="font-size: 16pt;"/>
                </btn-checkbox>
                <btn-checkbox class="mr-1" v-model="linkValue.mediaQuery.kiosk.visible" :title="'Select if visible on kiosk device|Visibilité sur kiosque'|tr">
                    <i class="fak fa-kiosk" style="font-size: 16pt;"/>
                </btn-checkbox>
            </template>
            <template v-else>
                <b-btn @click="linkValue.initMediaQuery()">
                    <span v-tr>Customize|Personnaliser</span>
                </b-btn>
            </template>
        </b-form-group>

        <template #modal-footer="{hide}">
            <div class="w-100 text-right d-flex">
                <b-btn @click="$emit('clear')" variant="secondary" class="text-danger mr-auto">
                    <span v-tr>Clear|Enlever</span>
                </b-btn>

                <b-btn @click="hide" class="mr-2">
                    <span v-tr>Cancel|Annuler</span>
                </b-btn>
                <b-btn variant="primary" @click="apply">
                    <span v-tr>Apply|Appliquer</span>
                </b-btn>
            </div>
        </template>
    </b-modal>
</template>

<script>
import BtnCheckbox from "@/components/editor/properties/inputs/btn-checkbox.vue";
import InputFile from "@/components/editor/properties/inputs/input-file.vue";
import InputGlyph from "@/components/editor/properties/inputs/input-glyph.vue";
import SelectCmsItem from "@/components/editor/properties/inputs/select-cms-item.vue";
import CmsLink from "@/helpers/cms/CmsLink.js";
import VueSelect from "vue-select";
import EInput from "../../../../../vue-components/components/e-input.vue";
import ETelInput from "../../../../../vue-components/components/e-tel-input.vue";
import ETranslationInput from "../../../../../vue-components/components/e-translation-input.vue";
import Network from "../../../../../vue-components/helpers/Network.js";

// link-picker #hot-reload-debug
export default {
    name: `link-picker`,
    components: {BtnCheckbox, SelectCmsItem, InputGlyph, ETranslationInput, InputFile, ETelInput, EInput, VueSelect},
    props: {
        value: {},
        hideCurrentPage: {type: Boolean},
        customizable: {type: Boolean},
        allowEmpty: {type: Boolean},
        visible: {type: Boolean}
    },
    data() {
        return {
            selectGlyph: false,
            linkTypes: CmsLink.linkTypes
        }
    },
    computed: {
        linkValue: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit(`input`, value);
            }
        },
        pickerVisible: {
            get() {
                return this.visible;
            },
            set(value) {
                this.$emit(`update:visible`, value);
            }
        },
        linkType() {
            return this.linkValue.getType();
        }
    },
    methods: {
        apply() {
            this.updateLink();
            this.pickerVisible = false;
            this.$emit(`apply`, this.linkValue);
        },
        onChangedPage(page) {
            this.linkValue.setPage(page);
        },
        onChangedItem(itemId) {
            this.linkValue.setItemId(itemId);
        },
        updateLink() {
            this.linkValue.updateLink();
        },
        reducePage(page) {
            return {
                id: page.id
            };
        },
        onLinkTypeChanged() {
            this.linkValue.name = ``;
            switch (this.linkValue.type) {
                case `page`:
                    if (this.cmsPages.length > 0) {
                        this.linkValue.value = this.reducePage(this.cmsPages[0].id);
                    } else {
                        this.linkValue.value = ``;
                    }
                    break;
                case `back`:
                case `next`:
                case `language`:
                    this.updateLink();
                    break;
                default:
                    this.linkValue.value = ``;
                    break;
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.input-link {
    padding: 4px;
    height: 52px;

    .input-group {
        height: 100%;
        align-items: center;

        .overview {
            display: inline-flex;
            flex-direction: column;
            justify-content: center;
            flex: 1;
            height: 100%;
            overflow: hidden;
            font-size: 10pt;
        }

        .type {
            font-weight: 500;
            margin-bottom: 3px;
        }

        .name, .url {
            font-size: 10pt;
            width: 100%;
            overflow: hidden;
            white-space: nowrap;
        }
    }
}
</style>
