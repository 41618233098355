<template>
    <b-navbar class="editor-submenu bg-white" :disabled="!active">
        <b-nav>
            <b-btn-group>
                <b-btn variant="light" size="sm" @click="onClick($event, 'undo')" :title="'Undo|Annuler'|tr" v-b-tooltip>
                    <i class="fas fa-undo fa-fw"/>
                </b-btn>
                <b-btn variant="light" size="sm" @click="onClick($event, 'redo')" :title="'Redo|Refaire'|tr" v-b-tooltip>
                    <i class="fas fa-redo fa-fw"/>
                </b-btn>
            </b-btn-group>

            <b-btn-group>
                <b-btn variant="light" size="sm" @click="onClick($event, 'bold')" :title="'Bold|Gras'|tr" v-b-tooltip>
                    <i class="fas fa-bold fa-fw"/>
                </b-btn>
                <b-btn variant="light" size="sm" @click="onClick($event, 'italic')" :title="'Italic|Italique'|tr" v-b-tooltip>
                    <i class="fas fa-italic fa-fw"/>
                </b-btn>
                <b-btn variant="light" size="sm" @click="onClick($event, 'underline')" :title="'Underline|Souligner'|tr" v-b-tooltip>
                    <i class="fas fa-underline fa-fw"/>
                </b-btn>
                <b-btn variant="light" size="sm" @click="onClick($event, 'strikeThrough')" :title="'Strike Through|Barrer'|tr" v-b-tooltip>
                    <i class="fas fa-strikethrough fa-fw"/>
                </b-btn>
            </b-btn-group>

            <b-btn-group>
                <b-select size="sm" value="default" @change="execCmd('fontSize', $event)">
                    <option selected disabled value="default" class="text-italic" v-tr>Font Size|Taille de police</option>
                    <option :value="2" v-tr>X-Small|Très petit</option>
                    <option :value="3" v-tr>Small|Petit</option>
                    <option :value="4" v-tr>Medium|Moyen</option>
                    <option :value="5" v-tr>Large|Large</option>
                    <option :value="6" v-tr>X-Large|Très large</option>
                    <option :value="7" v-tr>Biggest|Plus grand</option>
                </b-select>

<!--                <b-btn size="sm" @click="setFontSize(fontSize - 1)"><i class="fas fa-minus"/></b-btn>-->
<!--                <b-input size="sm" v-model="fontSize" @change="setFontSize"/>-->
<!--                <b-btn size="sm" @click="setFontSize(fontSize + 1)"><i class="fas fa-plus"/></b-btn>-->
            </b-btn-group>

            <b-btn-group>
                <b-btn variant="light" size="sm" @click="onClick($event, 'removeFormat')" :title="'Remove Format|Enlever le format'|tr" v-b-tooltip>
                    <i class="fas fa-pencil-slash fa-fw"/>
                </b-btn>
            </b-btn-group>

            <input-color class="ml-2" size="sm" auto-hide @change="execCmd('foreColor', $event)" use-css-var v-model="color" :title="'Text Color|Couleur du texte'|tr" v-b-tooltip>
                <i class="fas fa-font"/>
            </input-color>
            <input-color class="mx-2" size="sm" allow-transparent auto-hide @input="execCmd('backColor', $event)" use-css-var value="#eeeeee" :title="'Background Color|Couleur du fond'|tr" v-b-tooltip>
                <i class="fas fa-highlighter-line"/>
            </input-color>

            <b-btn-group>
                <b-btn variant="light" size="sm" @click="onClick($event, 'justifyLeft')" :title="'Align Left|Aligner à gauche'|tr" v-b-tooltip>
                    <i class="fas fa-align-left fa-fw"/>
                </b-btn>
                <b-btn variant="light" size="sm" @click="onClick($event, 'justifyCenter')" :title="'Center|Aligner au centre'|tr" v-b-tooltip>
                    <i class="fas fa-align-center fa-fw"/>
                </b-btn>
                <b-btn variant="light" size="sm" @click="onClick($event, 'justifyRight')" :title="'Align Right|Aligner à droite'|tr" v-b-tooltip>
                    <i class="fas fa-align-right fa-fw"/>
                </b-btn>
                <b-btn variant="light" size="sm" @click="onClick($event, 'justifyFull')" :title="'Justify|Justifier'|tr" v-b-tooltip>
                    <i class="fas fa-align-justify fa-fw"/>
                </b-btn>
            </b-btn-group>

            <slot></slot>
        </b-nav>
    </b-navbar>
</template>

<script>
import InputColor from "@/components/editor/properties/inputs/input-color.vue";

// editor-submenu #hot-reload-debug
import EColorPicker from "../../../vue-components/components/e-color-picker.vue";

export default {
    name: `editor-submenu`,
    components: {InputColor, EColorPicker},
    props: {
        disabled: {type: Boolean}
    },
    data() {
        return {
            previouslyFocus: null,
            lastSelection: null,
            selection: null,
            fontSize: `13pt`,
            active: false,
            color: `#000000`,
            highlightColor: `transparent`
        }
    },
    created() {
        // document.addEventListener(`focusout`, (e) => {
        //     this.previouslyFocus = e.target;
        // })
        document.addEventListener(`selectionchange`, this.updateSelection);
    },
    beforeDestroy() {
        document.removeEventListener(`selectionchange`, this.updateSelection);
    },
    methods: {
        updateSelection() {
            this.selection = document.getSelection();

            if (this.selection && this.selection.anchorNode) { // make sure it doesn't error if nothing is selected
                this.active = true;
                const style = window.getComputedStyle(this.selection.anchorNode.parentElement, null);
                this.fontSize = parseInt(style.getPropertyValue(`font-size`));
                this.color = style.getPropertyValue(`color`);
            } else {
                this.active = false;
            }
        },
        onClick(event, cmd, value) {
            event.preventDefault();

            // console.log(document.getSelection());
            // this.lastSelection = document.getSelection();
            // const editableParent = this.getEditableParent(this.lastSelection.focusNode.parentElement);
            // if (editableParent) {
            //     this.previouslyFocus = editableParent;
            // }

            this.execCmd(cmd, value);
        },
        getEditableParent(element) {
            while (element.parentElement) {
                if (element.parentElement.hasAttribute(`contenteditable`)) {
                    return element.parentElement;
                }
                element = element.parentElement;
            }
            return null;
        },
        setFontSize(fontSize) {
            this.execCmd(`fontSize`, `${fontSize}px`);
        },
        execCmd(cmd, value) {
            // const editableParent = this.getEditableParent(document.getSelection().focusNode.parentElement);
            //
            // if (!editableParent && this.previouslyFocus) {
            //     this.previouslyFocus.focus();
            // }

            document.execCommand(cmd, false, value);
            this.updateSelection();
            // if (this.previouslyFocus) {
            //     this.previouslyFocus.focus();
            // }
            // document.activeElement.focus();
        }
    }
}
</script>

<style lang="scss" scoped>
.editor-submenu {
    padding-top: 0;
    user-select: none;

    .btn-group {
        .btn + .btn {
            border-left: 1px solid rgba(black, 0.1);
        }

        & + .btn-group {
            margin-left: 5px;
        }
    }
}
</style>
