<template>
    <b-sidebar :visible="!floating || visible" :lazy="floating" no-close-on-esc right class="editor-sidebar cms-editor" :no-slide="noSlide"
               :class="{'has-tabs':hasTabs, 'floating': floating }" :width="`${width}px`" :header-class="headerClass" :body-class="bodyClass" :footer-class="footerClass">
        <slot name="sidebar"/>

        <template #header v-if="$scopedSlots.header || title">
            <slot name="header">
                <slot name="header-close">
                    <b-btn v-if="closeButton" variant="light" class="mr-2" @click="$emit('close')" :title="'Cancel|Annuler'|tr">
                        <i class="fas fa-arrow-left"/>
                    </b-btn>
                </slot>
                <h4 class="sidebar-title">{{title|tr}}</h4>
                <slot name="header-action"></slot>
            </slot>
        </template>

        <slot></slot>

        <template #footer v-if="$scopedSlots.footer">
            <slot name="footer"></slot>
        </template>
    </b-sidebar>
</template>

<script>
    export default {
        name: `editor-sidebar`,
        components: {},
        props: {
            title: {type: String, default: ``},
            floating: {type: Boolean},
            visible: {type: Boolean},
            hasTabs: {type: Boolean},
            closeButton: {type: Boolean},
            noSlide: {type: Boolean},
            headerClass: {type: String, default: ``},
            bodyClass: {type: String, default: ``},
            footerClass: {type: String, default: ``},
            width: {type: Number, default: 360}
        }
    }
</script>

<style lang="scss" scoped>
.editor-sidebar {
    --sidebar-width: 360px;

    position: relative;
    display: flex;
    flex-direction: column;
    //background: white;
    text-align: left;
    user-select: none;

    ::v-deep .editor-sidebar .b-sidebar-body {
        z-index: 1500 !important;
    }

    &:not(.floating) {
        width: calc(var(--sidebar-width) + 1px);// for border
        height: 100vh;
        border-left: 1px solid var(--border-color);
    }

    ::v-deep .b-sidebar-body {
        flex: 1;
        display: flex;
        flex-direction: column;
        padding: 10px;
        overflow: hidden auto;
        background: linear-gradient(180deg,#f1f5f9,#edf6f8);
    }

    ::v-deep .col-form-label {
        padding-bottom: 0;
    }

    ::v-deep .b-sidebar-header {
        display: flex;
        width: 100%;
        padding: 10px;
        border-bottom: none;
        background: white;
        color: #333;
        align-items: center;
        user-select: none;

        .sidebar-title {
            text-transform: initial;
            font-weight: 500;
            font-size: 14pt;
            letter-spacing: 1px;
            margin: 0;
        }

        ::v-deep h4 {
            margin-top: 0;
            margin-bottom: 0;
        }

        .close {
            position: relative;
            float: right;
            margin: 0 0 0 auto;
            padding: 10px 10px 12px;
            border-radius: 5px;
            background: #ddd;
            font-weight: 600;
            width: 40px !important;
            height: 40px !important;
            display: flex;
            align-items: center;
            justify-content: center;
            text-shadow: none;
            color: #444;
            opacity: 1;

            & + .btn {
                margin-left: 5px;
            }

            &:hover {
                background: #bbb;
            }
        }
    }

    ::v-deep .b-sidebar-footer {
        background: white;
        color: #333;
        display: flex;
        width: 100%;
        padding: 10px;
        justify-content: flex-end;
        border-top: none;
        align-items: center;

        ::v-deep .btn {
            padding: 10px 20px;

            &.btn-secondary {
                background: #eee;
                color: #333;
                border: 1px solid rgba(black, 0.15);
                outline: none;

                &:hover {
                    background: #ddd;
                }

                &:active {
                    background: #ccc;
                }
            }
        }
    }

    &.has-tabs {
        ::v-deep > .b-sidebar {
            > .b-sidebar-body {
                padding: 0;

                .nav-tabs {
                    border-radius: 0 !important;
                }
            }
        }
    }
}
</style>
