<template>
    <properties-component v-bind="$props" @close="$emit('close')">
        <b-form-group>
            <b-btn variant="outline-primary" block @click="edit">
                <i class="fas fa-link mr-1"/>
                <span v-tr>Edit Links|Modifier les liens</span>
            </b-btn>
        </b-form-group>

        <sidebar-group title="Link Style|Style des liens" :expanded.sync="styleExpanded" sub-group>
            <b-form-group>
                <template #label>
                    <btn-responsive-prop v-model="component" field="buttonType" no-label/>
                </template>
                <input-button-type v-model="component.mediaProxy.buttonType"/>
            </b-form-group>

            <b-form-group>
                <template #label>
                    <btn-responsive-prop v-model="component" field="borderRadius" no-label/>
                </template>
                <input-border-radius v-model="component.mediaProxy.borderRadius"/>
            </b-form-group>

            <b-form-group label-cols="4">
                <template #label>
                    <span v-tr>Display|Affichage</span>
                    <btn-responsive-prop v-model="component" field="display"/>
                </template>
                <b-select v-model="component.mediaProxy.display">
                    <option value="text" v-tr>Text only|Texte seulement</option>
                    <option value="icon" v-tr>Icon only|Icône seulement</option>
                    <option value="text-icon-top" v-tr>Text & Top Icon|Texte et icône haut</option>
                    <option value="text-icon-left" v-tr>Text & Left Icon|Texte et icône gauche</option>
                    <option value="text-icon-right" v-tr>Text & Right Icon|Texte et icône droite</option>
                </b-select>
            </b-form-group>

            <b-form-group label-cols="4">
                <template #label>
                    <span v-tr>Text Size|Taille texte</span>
                    <btn-responsive-prop v-model="component" field="size"/>
                </template>
                <b-select v-model="component.mediaProxy.size">
                    <option value="xs" v-tr>Very Small|Très petit</option>
                    <option value="sm" v-tr>Small|Petit</option>
                    <option value="md" v-tr>Normal</option>
                    <option value="lg" v-tr>Large</option>
                    <option value="xl" v-tr>Very Large|Très large</option>
                </b-select>
            </b-form-group>

            <b-form-group label-cols="4" v-if="component.mediaProxy.display !== `text`">
                <template #label>
                    <span v-tr>Icon Size|Taille icône</span>
                    <btn-responsive-prop v-model="component" field="iconSize"/>
                </template>
                <b-select v-model="component.mediaProxy.iconSize">
                    <option value="2xs" v-tr>Extra Small|Ultra petit</option>
                    <option value="xs" v-tr>Very Small|Très petit</option>
                    <option value="sm" v-tr>Small|Petit</option>
                    <option value="1x" v-tr>Normal</option>
                    <option value="lg" v-tr>Large</option>
                    <option value="xl" v-tr>Very Large|Très large</option>
                    <option value="2xl" v-tr>Extra Large|Ultra large</option>
                </b-select>
            </b-form-group>
        </sidebar-group>

        <sidebar-group title="Link Layout|Disposition des liens" :expanded.sync="layoutExpanded" sub-group>
            <b-form-group label-cols="5">
                <template #label>
                    <span v-tr>Layout|Disposition</span>
                    <btn-responsive-prop v-model="component" field="layout"/>
                </template>
                <b-select v-model="component.mediaProxy.layout">
                    <option value="horizontal" v-tr>Horizontal|Horizontale</option>
                    <option value="vertical" v-tr>Vertical|Verticale</option>
                </b-select>
            </b-form-group>

            <b-form-group>
                <btn-responsive-prop v-model="component" field="fillSize" for-checkbox/>
                <b-checkbox v-model="component.mediaProxy.fillSize" switch>
                    <span v-tr>Fill|Remplir</span>
                </b-checkbox>
            </b-form-group>

            <b-form-group label-cols="5">
                <template #label>
                    <span v-tr>Size Mode|Type de taille</span>
                    <btn-responsive-prop v-model="component" field="linkSizeMode"/>
                </template>
                <b-select v-model="component.mediaProxy.linkSizeMode">
                    <option value="auto" v-tr>Auto</option>
                    <option value="justify" v-tr>Uniform|Uniforme</option>
                    <option value="custom" v-tr>Custom|Personnalisée</option>
                </b-select>
            </b-form-group>

            <b-form-group label-cols="5" v-if="component.mediaProxy.linkSizeMode === 'custom'">
                <template #label>
                    <span v-tr>Size|Taille</span>
                    <btn-responsive-prop v-model="component" field="linkSize"/>
                </template>
                <b-select v-model="component.mediaProxy.linkSize">
                    <option v-for="n in 100" :key="`width-${n}`" :value="`${n*5}px`">{{n*5}}px</option>
                </b-select>
            </b-form-group>

            <b-form-group label-cols="5">
                <template #label>
                    <span v-tr>Spacing|Espacement</span>
                    <btn-responsive-prop v-model="component" field="spacing"/>
                </template>
                <b-select v-model="component.mediaProxy.spacing">
                    <option value="0px" v-tr>None|Sans</option>
                    <option v-for="n in 100" :key="`width-${n}`" :value="`${n}px`">{{n}}px</option>
                </b-select>
            </b-form-group>

            <b-form-group>
                <btn-responsive-prop v-model="component" field="collapsing" for-checkbox/>
                <b-checkbox v-model="component.mediaProxy.collapsing" switch>
                    <span v-tr>Collapsed|Menu déroulant</span>
                </b-checkbox>
            </b-form-group>

<!--            <template v-if="component.mediaProxy.collapsed">-->
<!--                <b-form-group label-cols="4">-->
<!--                    <template #label>-->
<!--                        <span v-tr>Label|Texte</span>-->
<!--                        <btn-responsive-prop v-model="component" field="collapseLabel"/>-->
<!--                    </template>-->
<!--                    <e-translation-input type="text" v-model="component.mediaProxy.collapseLabel" auto-translate no-margin/>-->
<!--                </b-form-group>-->
<!--                <b-form-group label-cols="4">-->
<!--                    <template #label>-->
<!--                        <span v-tr>Icon|Icône</span>-->
<!--                        <btn-responsive-prop v-model="component" field="collapseIcon"/>-->
<!--                    </template>-->
<!--                    <input-glyph v-model="component.mediaProxy.collapseIcon"/>-->
<!--                </b-form-group>-->
<!--            </template>-->
        </sidebar-group>

        <sidebar-group title="Divider|Séparateur" :expanded.sync="separatorExpanded" sub-group>
            <b-form-group>
                <btn-responsive-prop v-model="component" field="separator" for-checkbox/>
                <b-checkbox v-model="component.mediaProxy.separator" switch>
                    <span v-tr>Border between items|Bordure entre les éléments</span>
                </b-checkbox>
            </b-form-group>

            <template v-if="component.mediaProxy.separator">
                <b-form-group label-cols="4">
                    <template #label>
                        <span v-tr>Width|Épaisseur</span>
                        <btn-responsive-prop v-model="component" field="separatorWidth"/>
                    </template>
                    <b-select v-model="component.mediaProxy.separatorWidth">
                        <option value="none" v-tr>None|Sans</option>
                        <option v-for="n in 50" :key="`width-${n}`" :value="`${n}px`">{{n}}px</option>
                    </b-select>
                </b-form-group>

                <b-form-group label-cols="4">
                    <template #label>
                        <span v-tr>Color|Couleur</span>
                        <btn-responsive-prop v-model="component" field="separatorColor"/>
                    </template>
                    <input-color v-model="component.mediaProxy.separatorColor" use-css-var/>
                </b-form-group>
            </template>
        </sidebar-group>

        <b-modal v-model="editLinks" scrollable :title="'Edit Links|Modifier les liens'|tr">
            <b-card no-body>
                <vuedraggable v-model="component.links" class="list-group" handle=".drag-handle"
                              ghost-class="list-item-ghost" drag-class="list-item-drag" animation="150">
                    <navigation-link-list-view-item v-for="(link, index) in component.links" :link="link" :key="'link-' + index" @click="selectLink(link, index)"
                                                    @delete="deleteLink(index)"/>
                </vuedraggable>
            </b-card>

            <b-btn variant="primary" class="mt-3" block @click="addLink">
                <i class="fas fa-plus mr-1"/>
                <span v-tr>Add Link|Ajouter lien</span>
            </b-btn>
        </b-modal>

        <link-picker v-if="pickerActive" v-model="link" :visible.sync="pickerVisible" @apply="apply" customizable/>

        <slot></slot>
    </properties-component>
</template>

<script>
import BtnResponsiveProp from "@/components/editor/properties/components/btn-responsive-prop.vue";
import PropertiesComponent from "@/components/editor/properties/components/properties-component.vue";
import SidebarGroup from "@/components/editor/properties/components/sidebar-group.vue";
import InputBorderRadius from "@/components/editor/properties/inputs/input-border-radius.vue";
import InputButtonType from "@/components/editor/properties/inputs/input-button-type.vue";
import InputColor from "@/components/editor/properties/inputs/input-color.vue";
import InputGlyph from "@/components/editor/properties/inputs/input-glyph.vue";
import InputLink from "@/components/editor/properties/inputs/input-link.vue";
import LinkPicker from "@/components/editor/properties/inputs/link-picker.vue";
import SelectCmsItemField from "@/components/editor/properties/inputs/select-cms-item-field.vue";
import NavigationLinkListViewItem from "@/components/editor/properties/navigation-link-list-view-item.vue";
import CmsLink from "@/helpers/cms/CmsLink.js";
import Vuedraggable from "vuedraggable";
import EInput from "../../../../../vue-components/components/e-input.vue";
import ETelInput from "../../../../../vue-components/components/e-tel-input.vue";
import ETranslationInput from "../../../../../vue-components/components/e-translation-input.vue";

// properties-component-navigation-links #hot-reload-debug
export default {
    name: `properties-component-navigation-links`,
    components: {
        InputGlyph,
        InputColor,
        BtnResponsiveProp, LinkPicker, NavigationLinkListViewItem,
        SidebarGroup, InputButtonType, ETranslationInput, ETelInput, InputLink, SelectCmsItemField, InputBorderRadius, Vuedraggable, EInput, PropertiesComponent},
    extends: PropertiesComponent,
    data() {
        return {
            editLinks: false,
            pickerActive: false,
            pickerVisible: false,
            linkSelected: false,
            link: null,
            indexSelected: false,
            styleExpanded: false,
            layoutExpanded: false,
            separatorExpanded: false
        }
    },
    methods: {
        edit() {
            if (this.component.useMenuNavigation) {
                this.$emit(`edit-navigation`);
            } else {
                this.editLinks = true;
            }
        },
        selectLink(link, index) {
            this.linkSelected = link;
            this.indexSelected = index;
            this.link = new CmsLink(this.linkSelected);
            this.pickerActive = true;
            this.pickerVisible = true;
        },
        addLink() {
            this.linkSelected = null;
            this.indexSelected = null;
            this.link = new CmsLink();
            this.pickerActive = true;
            this.pickerVisible = true;
        },
        deleteLink(index) {
            this.component.links.removeItemAtIndex(index);
        },
        apply(link) {
            if (this.indexSelected !== null) {
                this.$set(this.component.links, this.indexSelected, link);
            } else {
                this.component.links.push(link);
            }
            this.linkSelected = link;
        },
        toggle(type, enabled) {
            if (enabled && !this.component.links[type.value]) {
                this.$set(this.component.links, type.value, {
                    enabled: true,
                    type: type.value,
                    glyph: type.glyph,
                    title: type.title,
                    prefix: type.prefix,
                    value: ``
                });
            } else {
                this.component.links[type.value].enabled = enabled;
            }
        },
        isEnabled(type) {
            return this.component.links.hasOwnProperty(type.value) && this.component.links[type.value].enabled;
        }
    }
}
</script>

<style lang="scss" scoped>
</style>
