<template>
    <div @click="$emit(`click`, fontFamily)" class="input-font-preview" :class="{'selected': selected}" :style="{fontFamily: fontFamily}">
        <slot>{{fontFamily}}</slot>
        <i v-if="selected" class="fas fa-check icon-check"/>
    </div>
</template>

<script>

// input-font-preview #hot-reload-debug
import store from "@/store.js";

export default {
    name: `input-font-preview`,
    props: {
        fontFamily: {type: String},
        showSample: {type: Boolean},
        selected: {type: Boolean},
        loadPreview: {type: Boolean}
    },
    mounted() {
        if (this.loadPreview) {
            if (this.$store.getters[`googleFonts/loadedFonts`].indexOf(this.fontFamily) < 0) {
                let link = document.createElement(`link`);
                link.setAttribute(`rel`, `stylesheet`);
                link.setAttribute(`type`, `text/css`);
                link.setAttribute(`href`, `https://fonts.googleapis.com/css2?family=${this.fontFamily}&text=${encodeURIComponent(this.fontFamily)}&display=swap`);
                document.head.appendChild(link);
                store.commit(`googleFonts/setPreviewLoaded`, this.fontFamily);
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.input-font-preview {
    display: inline-flex;
    position: relative;

    &.selected {

    }

    .icon-check {
        position: absolute;
    }
}
</style>
