<template>
    <editor-sidebar class="properties-page" has-tabs>
        <template #header>
            <h4 class="sidebar-title my-2" v-tr>Page Structure|Structure de la page</h4>
        </template>

        <b-tabs class="tabs-pills">
            <b-tab :title="'Content|Contenu'|tr" class="p-3">

                <b-form-group id="edit-style"  v-if="canWrite(Permission.CMS.feature.PAGES)">
                    <b-btn @click="$emit('edit-theme')" class="text-left py-3" block variant="outline-primary">
                        <span class="left">
                            <i class="fas fa-pen-paintbrush fa-fw mr-1"/>
                        </span>
                        <span v-tr>Edit Site Style|Modifier le style du site</span>
                    </b-btn>
                </b-form-group>

                <b-popover variant="primary" v-if="!cmsWebsite.design.init && cmsPage.blocks.length > 0" target="#edit-style" placement="bottom" show>
                    <template #title>
                        <virtual-assistant-avatar/>
                        <span v-tr>Let's Get Some Style|Ajoutons du style</span>
                    </template>
                    <div class="f-500 f-12" v-tr>Change colors, fonts, favicons and much more|Changez les couleurs, les polices, les favicons et bien plus encore</div>
                    <div class="text-right mt-2">
                        <b-btn variant="white" @click="cmsWebsite.design.init = true">
                            <span v-tr>Dismiss|Plus tard</span>
                        </b-btn>
                        <b-btn variant="primary" class="ml-2" @click="$emit(`edit-theme`)">
                            <span v-tr>Try It|Essayer</span>
                        </b-btn>
                    </div>
                </b-popover>

        <!--        <b-card no-body>-->
                    <b-list-group class="list-standard mb-2">
                        <b-list-group-item v-if="showNavigation" class="interactive" @click="$emit('edit-menu')">
                            <i class="fas fa-fw fa-browser mr-1"/>
                            <span class="name" v-tr>Header and Navigation|Menu et navigation</span>
                        </b-list-group-item>
                    </b-list-group>
                        <vuedraggable v-model="currentPage.blocks" class="list-group list-standard mb-2" handle=".drag-handle"
                                      ghost-class="list-item-ghost" drag-class="list-item-drag" animation="150" @start="drag = true">
                            <block-list-view-item v-for="block in currentPage.blocks" :block="block" :key="'properties-' + block.id"
                                                  @click="$emit('block-selected', block)" @duplicate="currentPage.duplicateBlock(block)" @delete="currentPage.deleteBlock(block)"/>
                        </vuedraggable>
                    <b-list-group class="list-standard">
                        <b-list-group-item v-if="showNavigation" class="interactive" @click="$emit('edit-footer')">
                            <i class="fas fa-fw fa-browser fa-flip-vertical mr-1"/>
                            <span class="name" v-tr>Footer|Bas de page</span>
                        </b-list-group-item>
                    </b-list-group>
        <!--        </b-card>-->

                <b-btn variant="primary" class="mt-3" block @click="$emit('add-block')">
                    <i class="fas fa-plus mr-1"/>
                    <span v-tr>Add Section|Ajouter section</span>
                </b-btn>
            </b-tab>
            <b-tab :title="'CMS|CMS'|tr" class="p-3">
                <b-card class="mb-3" v-if="currentPage.itemType">
                    <h4 v-tr>Template Item Linked|Elément du modèle</h4>
                    <template v-if="currentPage.itemType === 'universal-search'">
                        <div class="form-control d-flex align-items-center" disabled>
                            <h6 v-tr>Site Search Page|Page de recherche</h6>
                        </div>
                    </template>
                    <select-cms-item-type v-else v-model="currentPage.itemType" :item-types="cmsTypes" append-to-body disabled/>
                </b-card>
            </b-tab>
            <b-tab :title="'Page Style|Style de la page'|tr" class="p-3">
                <sidebar-group title="Background|Arrière-plan">
                    <b-form-group label-cols="4">
                        <template #label>
                            <span>Type</span>
                            <btn-responsive-prop v-model="currentPage" field="background"/>
                        </template>
                        <b-select v-model="currentPage.background">
                            <option value="none" v-tr>Default|Défaut</option>
                            <option value="image" v-tr>Image</option>
                            <option value="color" v-tr>Color|Couleur</option>
                        </b-select>
                    </b-form-group>

                    <b-form-group v-if="currentPage.background === 'image'">
                        <input-image v-model="currentPage.backgroundImage"/>
                    </b-form-group>

                    <b-form-group v-else-if="currentPage.background === 'color'">
                        <input-color v-model="currentPage.backgroundColor" allow-transparent button block :label="'Custom Color|Couleur personnalisée'"/>
                    </b-form-group>
                </sidebar-group>
            </b-tab>
        </b-tabs>
    </editor-sidebar>
</template>

<script>
import BlockListViewItem from "@/components/editor/properties/block-list-view-item.vue";
import BtnResponsiveProp from "@/components/editor/properties/components/btn-responsive-prop.vue";
import SidebarGroup from "@/components/editor/properties/components/sidebar-group.vue";
import InputBorderRadius from "@/components/editor/properties/inputs/input-border-radius.vue";
import InputColor from "@/components/editor/properties/inputs/input-color.vue";
import InputImage from "@/components/editor/properties/inputs/input-image.vue";
import SelectCmsItemType from "@/components/editor/properties/inputs/select-cms-item-type.vue";
import VirtualAssistantAvatar from "@/components/virtual-assistant-avatar.vue";
import EditorSidebar from "@/layout/editor-sidebar.vue";
import Vuedraggable from "vuedraggable";
import Permission from "@/../vue-components/helpers/permissions";

// properties-page #hot-reload-debug
export default {
    name: `properties-page`,
    components: {
        SidebarGroup,
        InputBorderRadius,
        InputColor,
        BtnResponsiveProp,
        InputImage,
        VirtualAssistantAvatar, SelectCmsItemType, Vuedraggable, BlockListViewItem, EditorSidebar},
    props: {
        value: {type: Object, required: true},
        showNavigation: {type: Boolean}
    },
    data() {
        return {
            Permission,
            drag: false
        }
    },
    computed: {
        currentPage: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit(`input`, value);
            }
        }
    }
}
</script>

<style lang="scss" scoped>
</style>
