<template>
    <div class="editor-page-container">
        <template v-if="!isEmbed">
            <slot name="sidebar-left"></slot>
        </template>
        <div class="editor-page" :class="[pageClass, { 'no-padding': noPadding}]">
            <slot></slot>
        </div>
        <template v-if="!isEmbed">
            <slot name="sidebar-right"></slot>
        </template>
    </div>
</template>

<script>
    export default {
        name: `editor-page`,
        components: {},
        props: {
            title: {type: String, default: ``},
            containerClass: {type: String, default: ``},
            pageClass: {type: String, default: ``},
            noPadding: {type: Boolean},
            loading: {type: Boolean}
        },
        computed: {
            isEmbed() {
                return this.$route.query.embed !== undefined;
            }
        }
    }
</script>

<style lang="scss" scoped>
.editor-page-container {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    background-color: #f0f0f0;

    .editor-page {
        flex: 10;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        overflow: hidden;
        padding: 10px;

        &.no-padding {
            padding: 0;
        }
    }

}
</style>
