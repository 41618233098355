<template>
    <div class="input-button-type">
        <btn-button-type v-for="(preset, index) in presets" :key="`type-${index}`" v-model="currentButtonType" :preset="preset"/>
    </div>
</template>

<script>
import BtnButtonType from "@/components/editor/properties/inputs/btn-button-type.vue";

// input-button-type #hot-reload-debug
export default {
    name: `input-button-type`,
    components: {BtnButtonType},
    props: {
        value: {type: String}
    },
    data() {
        return {
            presets: [`solid`, `outline`, `light`, `text`]
        }
    },
    computed: {
        currentButtonType: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit(`input`, value);
            }
        }
    }
}
</script>

<style lang="scss" scoped>
</style>
