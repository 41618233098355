<template>
    <div class="padding-rule-container" :class="['dir-' + direction, {'vertical': vertical}]" :style="{ '--max-width': max + 'px' }">
        <input v-model.number="paddingValue" type="range" class="padding-rule" :class="[{'vertical': vertical}]" :min="0" :max="max" :step="5" :title="`${value}px`"/>
        <span class="value-label mx-1">{{value}}px</span>
    </div>
</template>

<script>
// block-list-view-item #hot-reload-debug
export default {
    name: `padding-rule`,
    props: {
        value: {},
        max: {type: Number, default: 250},
        direction: {type: String, default: `left`}
    },
    computed: {
        vertical() {
            return this.direction === `top` || this.direction === `bottom`;
        },
        paddingValue: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit(`input`, value);
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.padding-rule-container {
    display: flex;
    align-items: center;
    height: 100%;
    --max-width: 250px;

    &.dir-right {
        direction: rtl;
    }

    .value-label {
        color: #777;
        padding-top: 1px;
        font-size: 8pt;
        font-weight: 500;
    }

    &.vertical {
        .value-label {
            position: absolute;
            top: 505px;
            left: 0;
        }
    }

    input[type=range].padding-rule {
        appearance: none;
        background: transparent;
        height: 21px;
        width: var(--max-width);

        &:focus {
            outline: none;
        }

        &::-webkit-slider-runnable-track {
            width: 100%;
            height: 20px;
            cursor: pointer;
            background: white url("~@/assets/img/inputs/rule-bg-horizontal.png") left 9px repeat-x;
            border-radius: 2px;
            box-sizing: border-box;
            border: 1px solid #bbb;
            margin-top: 1px;
        }

        &::-webkit-slider-thumb {
            height: 100%;
            width: 12px;
            border-radius: 3px;
            background: transparent url("~@/assets/img/inputs/rule-splitter-horizontal.png") center 90% no-repeat;
            cursor: pointer;
            appearance: none;
            opacity: 0.5;
            transition: opacity 150ms;
        }

        &:hover::-webkit-slider-thumb,
        &:focus::-webkit-slider-thumb {
            opacity: 1;
        }

        &.vertical {
            width: 16px;
            height: var(--max-width);
            //transform: rotate(180deg);
            writing-mode: vertical-lr;

            &::-webkit-slider-runnable-track {
                height: 100%;
                width: 16px;
                cursor: pointer;
                background: white url("~@/assets/img/inputs/rule-bg-vertical.png") 9px top repeat-y;
            }

            &::-webkit-slider-thumb {
                height: 12px;
                width: 100%;
                background: transparent url("~@/assets/img/inputs/rule-splitter-vertical.png") 90% center no-repeat;
            }
        }
    }

    &.dir-top {
        align-items: start;
            justify-content: center;
    }

    &.dir-bottom {
        align-items: end;
        justify-content: center;
        padding-bottom: 3px;

        .padding-rule {
            transform: scale(-1, 1) !important;
        }
    }
}
</style>
