<template>
    <div class="properties-component sidebar-group" :class="component.type + '-properties-component'">
        <div class="sidebar-group-header">
            <i class="fas float-right toggle-expanded" @click="component.expanded = !component.expanded"
               :class="[component.expanded ? 'fa-chevron-up' : 'fa-chevron-down']" style="line-height: 26px;"/>
            <b-checkbox v-if="!component.required" class="toggle-active float-right" switch size="lg" v-model="component.active"/>

            <b-btn v-if="component.allowBinding && !noBinding && bindingEnabled && component.active" :variant="component.useBinding ? 'primary' : 'white'" class="toggle-binding float-right mr-2" size="sm"
                   @click="component.useBinding = !component.useBinding" :title="'Bind to variable|Lier à une variable'|tr" v-b-tooltip>
                <i class="fas fa-code fa-fw"/>
            </b-btn>
            <b-btn v-if="component.allowMediaQuery && component.active" :variant="component.mediaQuery.expanded ? 'primary' : 'white'" class="toggle-binding float-right mr-2" size="sm"
                   @click="component.mediaQuery.toggle()" :title="'Toggle Responsive Options|Afficher/masquer les options responsives'|tr" v-b-tooltip>
                <i class="fas fa-fw fa-laptop-mobile"/>
            </b-btn>
            <b-btn v-if="component.allowAdvanced && !noAdvanced && component.active" :variant="component.advanced ? 'primary' : 'white'" class="toggle-binding float-right mr-2" size="sm"
                   @click="component.advanced = !component.advanced" :title="'Toggle Advanced Options|Afficher/masquer les options avancées'|tr" v-b-tooltip>
                <i class="fas fa-plus fa-fw"/>
            </b-btn>
            <h4>{{title|tr}}</h4>
        </div>

        <div class="sidebar-group-body" v-show="component.expanded" v-if="component.active">
            <slot></slot>
        </div>

        <sidebar-group v-if="component.active && component.mediaQuery.expanded" v-show="component.expanded" :title="'Device Display|Affichage par appareil'|tr" sub-group>
            <b-form-group>
                <btn-checkbox v-model="component.mediaQuery.desktop.visible" :title="'Select if visible on desktop device|Visibilité sur ordinateur'|tr">
                    <i class="fas fa-desktop" style="font-size: 16pt;"/>
                </btn-checkbox>
                <btn-checkbox v-model="component.mediaQuery.tablet.visible" :title="'Select if visible on tablet device|Visibilité sur tablette'|tr">
                    <i class="fas fa-tablet-screen" style="font-size: 16pt;"/>
                </btn-checkbox>
                <btn-checkbox v-model="component.mediaQuery.mobile.visible" :title="'Select if visible on mobile device|Visibilité sur mobile'|tr">
                    <i class="fas fa-mobile-iphone" style="font-size: 16pt;"/>
                </btn-checkbox>
                <btn-checkbox v-model="component.mediaQuery.kiosk.visible" :title="'Select if visible on kiosk device|Visibilité sur kiosque'|tr">
                    <i class="fak fa-kiosk" style="font-size: 16pt;"/>
                </btn-checkbox>
                <btn-checkbox v-model="component.mediaQuery.wheelchair.visible" :title="'Select if visible on kiosk with wheelchair mode|Visibilité sur kiosque en mode handicapé'|tr">
                    <i class="fas fa-wheelchair" style="font-size: 16pt;"/>
                </btn-checkbox>
            </b-form-group>
        </sidebar-group>
    </div>
</template>

<script>

// properties-component #hot-reload-debug
import SidebarGroup from "@/components/editor/properties/components/sidebar-group.vue";
import BtnCheckbox from "@/components/editor/properties/inputs/btn-checkbox.vue";
import EBtnCheckbox from "../../../../../vue-components/components/e-btn-checkbox.vue";

export default {
    name: `properties-component`,
    components: {BtnCheckbox, EBtnCheckbox, SidebarGroup},
    props: {
        value: {type: Object, required: true},
        boundItem: {type: Object},
        block: {type: Object},
        noBinding: {type: Boolean},
        noAdvanced: {type: Boolean},
        title: {type: String, required: true}
    },
    computed: {
        component: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit(`input`, value);
            }
        },
        previewIcon() {
            switch (this.previewMode) {
                case `mobile`:
                    return `fas fa-mobile-iphone`;
                case `tablet`:
                    return `fas fa-tablet-screen`;
                case `kiosk`:
                    return `fak fa-kiosk`;
                default:
                    return `fas fa-desktop`;
            }
        },
        bindingEnabled() {
            return this.cmsPage.itemType || (this.block.cmsEnabled && this.block.itemId);
        },
        itemTypeDef() {
            if (!this.block.itemTypeDef) {
                return null;
            }
            return this.block.itemTypeDef;
        }
    }
}
</script>

<style lang="scss" scoped>
.toggle-binding {
    position: relative;
    top: 2px;
    border-radius: 20px;
    padding: 1px 7px;
    line-height: 1;
}
</style>
