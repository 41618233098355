<template>
    <b-btn @click="toggleLanguage" class="btn-language" v-bind="$props" v-on="$listeners" v-b-tooltip :title="'Change language|Changer de langue'|tr">
        <img class="flag" v-if="currentLanguage === 'fr'" :src="getPublicIcon('flags/france')"/>
        <img class="flag" v-else :src="getPublicIcon('flags/united-states')"/>
        <i class="fas fa-caret-down"></i>
    </b-btn>
</template>

<script>
export default {
    name: `btn-language`,
    props: {
        value: {type: String},
        variant: {type: String},
        size: {type: String},
        block: {type: Boolean}
    },
    computed: {
        currentLanguage: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit(`input`, value);
            }
        }
    },
    methods: {
        toggleLanguage() {
            if (this.currentLanguage === `en`) {
                this.currentLanguage = `fr`;
            } else {
                this.currentLanguage = `en`;
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.btn-language {
    position: relative;
    display: flex;

    .flag {
        width: 24px;
        height: 24px;
    }

    .fa-caret-down {
        position: absolute;
        bottom: 0;
        right: 2px;
    }
}
</style>
