export default class ColorHelper {
    static getColorContrast(color) {
        const luminance = ColorHelper.hexToLuminance(color);
        return luminance <= 0.5 ? `#ffffff` : `#000000`;
    }

    static hexToLuminance(hex) {
        const rgb = ColorHelper.hexToRgb(hex);
        return ColorHelper.rgbToLuminance(rgb);
    }

    static rgbToLuminance(rgb) {
        return (0.299 * rgb.r + 0.587 * rgb.g + 0.114 * rgb.b) / 255;
    }

    static hexToRgb(hex) {
        let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        return result ? {
            r: parseInt(result[1], 16),
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16)
        } : { r: 0, g: 0, b: 0 };
    }
}
