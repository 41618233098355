<template>
    <div class="input-font">
        <b-form-group>
            <b-dropdown ref="dropdown" :variant="variant" block no-caret>
                <template #button-content>
                    <input-font-preview v-if="currentFont.font" :font-family="currentFont.font.family"/>
                    <template v-else>
                        <span v-tr>Select Font|Choisir une police</span>
                    </template>
                    <i class="fas fa-caret-down ml-auto"/>
                </template>

                <template  v-if="googleFonts">
                    <b-dropdown-item v-for="(font, index) in bestOf" :key="`best-${index}`" @click="selectFont(font)">
                        <input-font-preview class="pl-3" :font-family="font.family" load-preview/>
                    </b-dropdown-item>
                    <b-dropdown-divider/>
                    <b-dropdown-item @click="show">
                        <i class="fas fa-folder-open"/>
                        <span v-tr>More Fonts|Plus de polices</span>
                    </b-dropdown-item>
                </template>
                <template v-else>
                    <span v-tr>Loading...|Chargement...</span>
                </template>
            </b-dropdown>
        </b-form-group>

        <b-form-group label-cols="4" :label="'Weight|Épaisseur'|tr">
            <b-select @change="onChanged" v-model="currentFont.fontWeight" :style="{fontWeight: currentFont.fontWeight}">
                <option value="100" style="font-weight: 100;" v-tr>Light|Fin</option>
                <option value="400" style="font-weight: 400;" v-tr>Normal</option>
                <option value="500" style="font-weight: 500;" v-tr>Semi-bold|Demi</option>
                <option value="700" style="font-weight: 700;" v-tr>Bold|Gras</option>
            </b-select>
        </b-form-group>

        <b-form-group class="mb-1" label-cols="4" :label="'Spacing|Espacement'|tr">
            <b-select @change="onChanged" v-model="currentFont.letterSpacing">
                <option v-for="n in 20" :key="`spacing-${n}`" :value="`${(n - 6) / 2}px`" :style="{letterSpacing: `${(n - 6) / 2}px`}">{{100 + ((n - 6) * 10)}}%</option>
            </b-select>
        </b-form-group>

        <editor-sidebar v-if="googleFonts" floating class="font-picker" :visible="pickerVisible" title="Select Link|Choisir un lien" close-button @close="hide" @hide="$emit('reset')">
            <template #header-action>
                <b-btn variant="primary" @click="hide" class="ml-auto" v-tr>Apply|Appliquer</b-btn>
            </template>
            <e-search-input v-model="search"/>
            <b-list-group class="overflow-auto">
                <b-list-group-item class="interactive" @click="selectFont(font)" v-for="(font, index) in googleFonts" :key="index" :style="{ fontFamily: font.family }">
                    {{font.family}}
                </b-list-group-item>
            </b-list-group>
        </editor-sidebar>
    </div>
</template>

<script>
import InputFontPreview from "@/components/editor/properties/inputs/input-font-preview.vue";
import EditorSidebar from "@/layout/editor-sidebar.vue";
import ESearchInput from "../../../../../vue-components/components/e-search-input.vue";

// input-font #hot-reload-debug
export default {
    name: `input-font`,
    components: {ESearchInput, EditorSidebar, InputFontPreview},
    props: {
        value: {},
        variant: {type: String, default: `outline-white`}
    },
    data() {
        return {
            pickerVisible: false,
            search: ``
        }
    },
    computed: {
        currentFont: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit(`input`, value);
                this.onChanged();
            }
        },
        bestOf() {
            return this.$store.state.googleFonts.fonts.slice(0, 30);
        },
        googleFonts() {
            if (!this.search) {
                return this.$store.state.googleFonts.fonts;
            }
            const search = new RegExp(this.search, `gi`);
            return this.$store.state.googleFonts.fonts.filter(f => search.test(f.family));
        }
    },
    mounted() {
        if (!this.currentFont) {
            this.currentFont = {
                fontFamily: `verdana`,
                fontWeight: 400,
                letterSpacing: `0`,
                weightRange: {}
            }
        }
    },
    methods: {
        onChanged() {
            this.$emit(`apply`, this.currentFont);
        },
        selectFont(font) {
            this.currentFont.font = font;
            delete this.currentFont.font.files;
            this.currentFont.fontFamily = font.family;
            let weightRange = {};
            if (font.axes) {
                const weightAxis = font.axes.find(a => a.tag = `wght`);
                if (weightAxis) {
                    weightRange = {start: weightAxis.start, end: weightAxis.end};
                }
            }
            this.currentFont.weightRange = weightRange;
            this.onChanged();
        },
        show() {
            this.pickerVisible = true;
        },
        hide() {
            this.pickerVisible = false;
        }
    }
}

/*
 * Example of font result
 * {
 *     "family": "Open Sans",
 *     "variants": [
 *         "300",
 *         "regular",
 *         "500",
 *         "600",
 *         "700",
 *         "800",
 *         "300italic",
 *         "italic",
 *         "500italic",
 *         "600italic",
 *         "700italic",
 *         "800italic"
 *     ],
 *     "subsets": [
 *         "cyrillic",
 *         "cyrillic-ext",
 *         "greek",
 *         "greek-ext",
 *         "hebrew",
 *         "latin",
 *         "latin-ext",
 *         "math",
 *         "symbols",
 *         "vietnamese"
 *     ],
 *     "version": "v40",
 *     "lastModified": "2023-12-14",
 *     "files": {
 *         "300": "https://fonts.gstatic.com/s/opensans/v40/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsiH0C4nY1M2xLER.ttf",
 *         "500": "https://fonts.gstatic.com/s/opensans/v40/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjr0C4nY1M2xLER.ttf",
 *         "600": "https://fonts.gstatic.com/s/opensans/v40/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsgH1y4nY1M2xLER.ttf",
 *         "700": "https://fonts.gstatic.com/s/opensans/v40/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsg-1y4nY1M2xLER.ttf",
 *         "800": "https://fonts.gstatic.com/s/opensans/v40/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgshZ1y4nY1M2xLER.ttf",
 *         "regular": "https://fonts.gstatic.com/s/opensans/v40/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjZ0C4nY1M2xLER.ttf",
 *         "300italic": "https://fonts.gstatic.com/s/opensans/v40/memQYaGs126MiZpBA-UFUIcVXSCEkx2cmqvXlWq8tWZ0Pw86hd0Rk5hkaVcUwaERZjA.ttf",
 *         "italic": "https://fonts.gstatic.com/s/opensans/v40/memQYaGs126MiZpBA-UFUIcVXSCEkx2cmqvXlWq8tWZ0Pw86hd0Rk8ZkaVcUwaERZjA.ttf",
 *         "500italic": "https://fonts.gstatic.com/s/opensans/v40/memQYaGs126MiZpBA-UFUIcVXSCEkx2cmqvXlWq8tWZ0Pw86hd0Rk_RkaVcUwaERZjA.ttf",
 *         "600italic": "https://fonts.gstatic.com/s/opensans/v40/memQYaGs126MiZpBA-UFUIcVXSCEkx2cmqvXlWq8tWZ0Pw86hd0RkxhjaVcUwaERZjA.ttf",
 *         "700italic": "https://fonts.gstatic.com/s/opensans/v40/memQYaGs126MiZpBA-UFUIcVXSCEkx2cmqvXlWq8tWZ0Pw86hd0RkyFjaVcUwaERZjA.ttf",
 *         "800italic": "https://fonts.gstatic.com/s/opensans/v40/memQYaGs126MiZpBA-UFUIcVXSCEkx2cmqvXlWq8tWZ0Pw86hd0Rk0ZjaVcUwaERZjA.ttf"
 *     },
 *     "category": "sans-serif",
 *     "kind": "webfonts#webfont",
 *     "menu": "https://fonts.gstatic.com/s/opensans/v40/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjZ0B4maVc.ttf"
 * }
 */
</script>

<style lang="scss" scoped>
.input-font {
    ::v-deep .dropdown-toggle {
        display: flex !important;
        align-items: center;
    }
}
</style>
