<template>
    <div class="input-color" :class="{ 'inline': inline }">
        <b-dropdown ref="dropdown" :variant="variant" :class="[button ? 'button': 'simple', `size-${size}`, {'block': block}]" no-caret>
            <template #button-content>
                <input-color-preview :size="size" :color="color" :title="title">
                    <slot v-if="!button">
                        <i v-if="color === 'default' || color === 'inherit'" class="fas fa-droplet-slash"/>
                    </slot>
                </input-color-preview>
                <slot v-if="button">
                    <div class="content">
                        <h5>{{ label|tr }}</h5>
                        <div>{{ colorComputed }}</div>
                    </div>
                </slot>
            </template>

            <div v-if="!noVariants" class="color-palette">
                <h5 v-tr>Theme|Thème</h5>
                <input-color-preview v-if="allowDefault" color="default" @click="setColor('default')" :selected="color === 'default'"
                                     :title="'By default|Par défaut'|tr">
                    <i class="fas fa-droplet-slash"/>
                </input-color-preview>
                <input-color-preview v-else-if="allowInherit" color="inherit" @click="setColor('inherit')" :selected="color === 'inherit'"
                                     :title="'Inherit|Hériter'|tr">
                    <i class="fas fa-droplet-slash"/>
                </input-color-preview>
                <input-color-preview v-for="(c, key) in cmsWebsite.design.colors" :color="key" @click="setColor(key)" :selected="c === color || key === color"
                                     :title="key" :key="`preset-${c}`"/>
                <input-color-preview v-if="allowTransparent" color="transparent" @click="setColor('transparent')" :selected="color === 'transparent'"
                                     title="Transparent"/>
                <b-dropdown-divider/>
            </div>

            <div v-if="!noPresets" class="color-palette">
                <input-color-preview v-for="(c, index) in presets" :color="c" @click="color = c" :selected="c === color" :key="`color-${index}`" size="sm"/>
                <b-dropdown-divider/>
            </div>
            <b-dropdown-item @click="show">
                <i class="fas fa-palette"/>
                <span v-tr>More Colors|Plus de couleurs</span>
            </b-dropdown-item>
        </b-dropdown>

        <dialog class="color-picker-dialog" ref="colorPicker">
            <template v-if="pickerEnabled">
                <div class="overlay"></div>
                <div class="content">
                    <sketch-picker :value="color" @input="color = $event.hex8" @ok="close" @cancel="close"/>
                </div>
                <div class="text-center p-2">
                    <b-btn @click="close"><span v-tr>Apply|Appliquer</span></b-btn>
                </div>
            </template>
        </dialog>
    </div>
</template>

<script>
import {Sketch} from 'vue-color'
import InputColorPreview from "@/components/editor/properties/inputs/input-color-preview.vue";

// input-color #hot-reload-debug
export default {
    name: `input-color`,
    components: {'sketch-picker': Sketch, InputColorPreview},
    props: {
        value: {type: String},
        variant: {type: String, default: `light`},
        label: {type: String},
        size: {type: String, default: `md`},
        title: {type: String},
        block: {type: Boolean},
        useCssVar: {type: Boolean},
        inline: {type: Boolean},
        autoHide: {type: Boolean},
        allowTransparent: {type: Boolean}, // bg only
        allowInherit: {type: Boolean}, // glyph only
        allowDefault: {type: Boolean}, // text only
        button: {type: Boolean},
        noVariants: {type: Boolean},
        noPresets: {type: Boolean},
        presets: {
            type: Array, default: () => [
                `#ff1616`,
                `#ff5983`,
                `#ff5810`,
                `#ff990a`,
                `#ffb90f`,
                `#ded222`,
                `#7bbd4b`,
                `#558632`,
                `#32864a`,
                `#3e96a5`,
                `#00aecc`,
                `#00c3e6`,
                `#0099f3`,
                `#006eff`,
                `#4058c5`,
                `#80428b`,
                `#8c52ff`,
                `#805140`,
                `#635454`,
                `#a49393`,
                `#eed6d3`,
                `#e8b4b8`,
                `#e3bc9a`,
                `#f3dbb2`,
                `#ffffff`,
                `#dddddd`,
                `#bbbbbb`,
                `#999999`,
                `#777777`,
                `#555555`,
                `#333333`,
                `#000000`
            ]
        },
    },
    data() {
        return {
            pickerEnabled: false,
            visible: false
        }
    },
    computed: {
        color: {
            get() {
                if (this.allowInherit && this.value === `inherit`) {
                    return this.value;
                }
                if (this.useCssVar && this.value && this.value.startsWith(`var(`)) {
                    return this.value.replace(`var(--`, ``).replace(`-color)`, ``);
                }
                return this.value;
            },
            set(value) {
                if (this.allowInherit && value === `inherit`) {
                    // don't change value here
                } else if (this.useCssVar && value && !value.startsWith(`#`)) {
                    value = `var(--${value}-color)`;
                }
                this.$emit(`input`, value);
                this.$emit(`change`, value);
            }
        },
        colorComputed() {
            return this.cmsWebsite.getColor(this.color);
        },
        style() {
            if (this.color === `default` || this.color === `inherit`) {
                return {background: `#000`};
            } else {
                return {background: this.color + ` !important`}
            }
        }
    },
    methods: {
        setColor(color) {
            this.color = color;
            if (this.autoHide && this.$refs.dropdown) {
                this.$refs.dropdown.hide();
            }
        },
        show() {
            this.pickerEnabled = true;
            this.$refs.colorPicker.showModal();
        },
        close() {
            this.$refs.colorPicker.close();
        }
    }
}
</script>

<style lang="scss" scoped>
.input-color {
    &.inline {
        display: inline-flex;
    }
}

.dropdown {
    &.block {
        width: 100%;
    }

    &.simple {
        ::v-deep .btn {
            padding: 0 !important;
            border: none;
        }
    }

    &.button ::v-deep .btn {
        border: 2px solid var(--border-color);
        display: flex;
        flex-direction: row;
        text-align: left;
        align-items: center;

        .content {
            h4, h5 {
                margin-bottom: 2px;
            }

            padding-left: 10px;
        }
    }

    ::v-deep .dropdown-menu {
        width: 324px;

        .color-palette {
            padding: 0 8px;
        }

        .input-color-preview {
            margin: 2px;
        }
    }
}

.color-button {
    display: inline-flex;
    width: 32px;
    height: 32px;
    border: 2px solid rgba(black, 0.2);
    border-radius: 4px;
}

.color-picker-dialog {
    padding: 0;
    border: none;
    border-radius: 4px;

    .vc-photoshop {
        font-family: inherit;

        .vc-ps-fields .vc-input__label,
        .vc-ps-fields .vc-input__desc {
            color: #000;
        }
    }
}
</style>
